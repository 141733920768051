import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserLikePost from './user-like-post';
import UserLikePostDetail from './user-like-post-detail';
import UserLikePostUpdate from './user-like-post-update';
import UserLikePostDeleteDialog from './user-like-post-delete-dialog';

const UserLikePostRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserLikePost />} />
    <Route path="new" element={<UserLikePostUpdate />} />
    <Route path=":id">
      <Route index element={<UserLikePostDetail />} />
      <Route path="edit" element={<UserLikePostUpdate />} />
      <Route path="delete" element={<UserLikePostDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserLikePostRoutes;
