import { IExperiences } from 'app/shared/model/experiences.model';
import { IPosts } from 'app/shared/model/posts.model';
import { IAlbums } from 'app/shared/model/albums.model';
import { ILocations } from 'app/shared/model/locations.model';
import { IRegions } from 'app/shared/model/regions.model';

export interface IImages {
  id?: number;
  cautionMessage?: string | null;
  imageId?: number | null;
  createdDate?: number | null;
  description?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  fkAlbumAddDate?: number | null;
  modifiedDate?: number | null;
  fkRegionId?: number | null;
  name?: string | null;
  fkAlbumId?: number | null;
  watermarkedUrl?: string | null;
  overallAccessibility?: number | null;
  tags?: string | null;
  s3Key?: string | null;
  url?: string | null;
  fileName?: string | null;
  fkLocationId?: number | null;
  fkRegionAddDate?: number | null;
  info?: string | null;
  year?: string | null;
  experiences?: IExperiences[] | null;
  posts?: IPosts[] | null;
  albums?: IAlbums | null;
  locations?: ILocations | null;
  regions?: IRegions | null;
}

export const defaultValue: Readonly<IImages> = {};
