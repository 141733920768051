import { IImages } from 'app/shared/model/images.model';

export interface ILocations {
  id?: number;
  locationId?: number | null;
  defaultImageUrl?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  name?: string | null;
  images?: IImages | null;
}

export const defaultValue: Readonly<ILocations> = {};
