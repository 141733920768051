import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs.ts';

import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import SideBar from './shared/layout/sidebar/SideBar';
import ScrollToTop from './scrollTop';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();

  let loggedIn = localStorage.getItem('user') || sessionStorage.getItem('user');

  useEffect(() => {
    if (loggedIn) {
      dispatch(getSession(JSON.parse(loggedIn)));
    }
    // dispatch(getProfile());
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const userName = useAppSelector(state => state.authentication.account.name);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  /** SideBar Handle **/

  const paddingTop = '60px';
  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container" style={{ paddingTop }}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated || loggedIn}
            isAdmin={isAdmin}
            currentLocale={currentLocale}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
            userName={userName}
          />
        </ErrorBoundary>
        {/* <div className="container-fluid view-container" id="app-view-container"> */}
        <div className="wrap">
          <Row className="g-0 min-h">
            {!!isAuthenticated && (
              <Col md="3" xl="2">
                <SideBar />
              </Col>
            )}
            <Col md={`${!!isAuthenticated ? 9 : 12}`} xl={`${!!isAuthenticated ? 10 : 12}`}>
              <div className="container-fluid view-container" id="app-view-container">
                {/* {isAuthenticated &&
                    <div className="test" style={{ marginLeft: '20px', marginRight: '20px' }}>
                      <ValidatedForm defaultValues={{}} onSubmit={saveEntity}>
                        <ValidatedField
                          className="col-md-12"
                          id="userPrompt"
                          name="userPrompt"
                          data-cy="userPrompt"
                          placeholder="Let me know what you want to build..."
                          validate={{
                            required: { value: true, message: "Please enter valid prompt!!" },
                          }}
                        />
                        <Button
                          className="md-1"
                          color="save"
                          id="save-entity"
                          data-cy="entityCreateSaveButton"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </ValidatedForm>
                    </div>
                  } */}
                <ErrorBoundary>
                  <ScrollToTop />
                  <AppRoutes />
                </ErrorBoundary>
                {/* <Footer /> */}
              </div>
            </Col>
          </Row>
        </div>
        {/* <Card className="jh-card">
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </Card> */}

        {/* <Footer /> */}
        {/* </div> */}
      </div>
    </BrowserRouter>
  );
};

export default App;
