import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Posts from './posts';
import PostsDetail from './posts-detail';
import PostsUpdate from './posts-update';
import PostsDeleteDialog from './posts-delete-dialog';
import Photos from './photos';
import Comment from './comment';
import ArchivedPost from './archived-post';
import ArchivedComment from './archive-comment';
import usersReducer from '../users/users.reducer';
import Users from './users';

const PostsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Photos />} />
    <Route path="user" element={<Users />} />
    <Route path="currentComment" element={<Comment />} />
    <Route path="archived" element={<ArchivedPost />} />
    <Route path="archiveComment" element={<ArchivedComment />} />
    <Route path="new" element={<PostsUpdate />} />
    <Route path=":id">
      <Route index element={<PostsDetail />} />
      <Route path="edit" element={<PostsUpdate />} />
      <Route path="delete" element={<PostsDeleteDialog />} />
    </Route>
    {/* <Route index element={<Posts />} />
    <Route path="new" element={<PostsUpdate />} />
    <Route path=":id">
      <Route index element={<PostsDetail />} />
      <Route path="edit" element={<PostsUpdate />} />
      <Route path="delete" element={<PostsDeleteDialog />} />
    </Route> */}
  </ErrorBoundaryRoutes>
);

export default PostsRoutes;
