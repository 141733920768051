import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CommunityImages from './community-images';
import CommunityImagesDetail from './community-images-detail';
import CommunityImagesUpdate from './community-images-update';
import CommunityImagesDeleteDialog from './community-images-delete-dialog';

const CommunityImagesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CommunityImages />} />
    <Route path="new" element={<CommunityImagesUpdate />} />
    <Route path=":id">
      <Route index element={<CommunityImagesDetail />} />
      <Route path="edit" element={<CommunityImagesUpdate />} />
      <Route path="delete" element={<CommunityImagesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CommunityImagesRoutes;
