import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './posts.reducer';

export const PostsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const postsEntity = useAppSelector(state => state.posts.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="postsDetailsHeading">
          <Translate contentKey="myApp.posts.detail.title">Posts</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{postsEntity.id}</dd>
          <dt>
            <span id="postId">
              <Translate contentKey="myApp.posts.postId">Post Id</Translate>
            </span>
          </dt>
          <dd>{postsEntity.postId}</dd>
          <dt>
            <span id="commentsCount">
              <Translate contentKey="myApp.posts.commentsCount">Comments Count</Translate>
            </span>
          </dt>
          <dd>{postsEntity.commentsCount}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="myApp.posts.content">Content</Translate>
            </span>
          </dt>
          <dd>{postsEntity.content}</dd>
          <dt>
            <span id="isArchived">
              <Translate contentKey="myApp.posts.isArchived">Is Archived</Translate>
            </span>
          </dt>
          <dd>{postsEntity.isArchived}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="myApp.posts.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{postsEntity.createdDate}</dd>
          <dt>
            <span id="fkflaggerId">
              <Translate contentKey="myApp.posts.fkflaggerId">Fkflagger Id</Translate>
            </span>
          </dt>
          <dd>{postsEntity.fkflaggerId}</dd>
          <dt>
            <span id="isFlagged">
              <Translate contentKey="myApp.posts.isFlagged">Is Flagged</Translate>
            </span>
          </dt>
          <dd>{postsEntity.isFlagged}</dd>
          <dt>
            <span id="isLiked">
              <Translate contentKey="myApp.posts.isLiked">Is Liked</Translate>
            </span>
          </dt>
          <dd>{postsEntity.isLiked}</dd>
          <dt>
            <span id="likesCount">
              <Translate contentKey="myApp.posts.likesCount">Likes Count</Translate>
            </span>
          </dt>
          <dd>{postsEntity.likesCount}</dd>
          <dt>
            <span id="location">
              <Translate contentKey="myApp.posts.location">Location</Translate>
            </span>
          </dt>
          <dd>{postsEntity.location}</dd>
          <dt>
            <span id="fkcommunityimageId">
              <Translate contentKey="myApp.posts.fkcommunityimageId">Fkcommunityimage Id</Translate>
            </span>
          </dt>
          <dd>{postsEntity.fkcommunityimageId}</dd>
          <dt>
            <span id="fkimageId">
              <Translate contentKey="myApp.posts.fkimageId">Fkimage Id</Translate>
            </span>
          </dt>
          <dd>{postsEntity.fkimageId}</dd>
          <dt>
            <span id="fkRegionId">
              <Translate contentKey="myApp.posts.fkRegionId">Fk Region Id</Translate>
            </span>
          </dt>
          <dd>{postsEntity.fkRegionId}</dd>
          <dt>
            <span id="fkflaggedUserId">
              <Translate contentKey="myApp.posts.fkflaggedUserId">Fkflagged User Id</Translate>
            </span>
          </dt>
          <dd>{postsEntity.fkflaggedUserId}</dd>
          <dt>
            <span id="fkUserId">
              <Translate contentKey="myApp.posts.fkUserId">Fk User Id</Translate>
            </span>
          </dt>
          <dd>{postsEntity.fkUserId}</dd>
          <dt>
            <span id="flagsCount">
              <Translate contentKey="myApp.posts.flagsCount">Flags Count</Translate>
            </span>
          </dt>
          <dd>{postsEntity.flagsCount}</dd>
          <dt>
            <Translate contentKey="myApp.posts.images">Images</Translate>
          </dt>
          <dd>{postsEntity.images ? postsEntity.images.id : ''}</dd>
          <dt>
            <Translate contentKey="myApp.posts.regions">Regions</Translate>
          </dt>
          <dd>{postsEntity.regions ? postsEntity.regions.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/posts" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/posts/${postsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PostsDetail;
