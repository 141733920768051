import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRegions } from 'app/shared/model/regions.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity, reset, getEntityImages } from './regions.reducer';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export const RegionsUpdate = () => {
  console.log('Entererer');

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const regionsEntityData = useAppSelector(state => state.regions.entity);
  const loading = useAppSelector(state => state.regions.loading);
  const updating = useAppSelector(state => state.regions.updating);
  const updateSuccess = useAppSelector(state => state.regions.updateSuccess);

  const [regionsEntity, setRegionsEntity] = useState(isNew ? undefined : regionsEntityData);
  const [imageLoading, setImageLoading] = useState(isNew ? false : true);
  const [regionImages, setRegionImages] = useState([]);
  const [imagesModal, setImagesModal] = useState(false);

  const imagesModalHandle = () => setImagesModal(!imagesModal);

  const handleClose = () => {
    navigate('/map-regions');
  };

  useEffect(() => {
    (async () => {
      if (isNew) {
        dispatch(reset());
      } else {
        dispatch(getEntity(id));
        const { payload } = await dispatch(getEntityImages(id));
        let data: any = payload;
        setRegionImages(data);
        setImageLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const imageHandler = url => {
    setRegionsEntity(prev => {
      return {
        ...prev,
        defaultImageUrl: url,
      };
    });
    imagesModalHandle();
  };

  const saveEntity = values => {
    const entity = {
      ...regionsEntity,
      ...values,
    };
    console.log(entity, 'entityentity');

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...regionsEntity,
        };

  return (
    <>
      {loading || imageLoading || updating ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h3 id="myApp.regions.home.createOrEditLabel" data-cy="RegionsCreateUpdateHeading" className="page-title">
              {isNew ? (
                <Translate contentKey="myApp.regions.home.createLabel">Create New Region</Translate>
              ) : (
                <Translate contentKey="myApp.regions.home.createOrEditLabel">Edit Map region profile</Translate>
              )}
            </h3>
          </Col>
        </Row>
        <Row className="image-edit-form">
          <Col md="12">
            <Card>
              <CardBody className="py-0 px-1">
                <ValidatedForm className="row" defaultValues={defaultValues()} onSubmit={saveEntity}>
                  {/* {!isNew ? (
                    <ValidatedField
                      name="id"
                      required
                      readOnly
                      id="regions-id"
                      label={translate('global.field.id')}
                      validate={{ required: true }}
                    />
                  ) : null} */}
                  {isNew ? (
                    <ValidatedField
                      id="images-file"
                      name="file"
                      label={'Choose File'}
                      validate={{ required: true }}
                      data-cy="file"
                      type="file"
                      className="col-12 col-md-6 col-lg-4 position-relative"
                      onChange={event => {
                        console.log(event);
                      }}
                    />
                  ) : (
                    <Col md={12} className="mb-3">
                      <h5>Cover Image</h5>
                      <div className="img-edit">
                        <img className="p-0 w-100" src={regionsEntity?.defaultImageUrl} />
                        <Button className="theme-btn rounded-0 w-100" onClick={imagesModalHandle}>
                          <FontAwesomeIcon icon="camera" /> &nbsp; Change Image
                        </Button>
                      </div>

                      <Modal isOpen={imagesModal} toggle={imagesModalHandle}>
                        <ModalHeader toggle={imagesModalHandle}>Map Gallery</ModalHeader>
                        <ModalBody className="map-gallery ">
                          <Row className="g-3">
                            {regionImages &&
                              regionImages.length > 0 &&
                              regionImages.map((item, index) => {
                                return (
                                  <Col md="3">
                                    <img src={item?.url} className="select-img" onClick={() => imageHandler(item?.url)} />
                                  </Col>
                                );
                              })}
                          </Row>
                        </ModalBody>
                      </Modal>
                    </Col>
                  )}

                  <ValidatedField
                    label={translate('myApp.regions.name')}
                    id="regions-name"
                    name="name"
                    data-cy="name"
                    type="text"
                    className="col-12 col-md-6 col-lg-4"
                  />
                  <ValidatedField
                    label={translate('myApp.regions.latitude')}
                    id="regions-latitude"
                    name="latitude"
                    data-cy="latitude"
                    type="text"
                    className="col-12 col-md-6 col-lg-4"
                  />
                  <ValidatedField
                    label={translate('myApp.regions.longitude')}
                    id="regions-longitude"
                    name="longitude"
                    data-cy="longitude"
                    type="text"
                    className="col-12 col-md-6 col-lg-4"
                  />
                  <ValidatedField
                    label={translate('myApp.regions.maxZoom')}
                    id="regions-maxZoom"
                    name="maxZoom"
                    data-cy="maxZoom"
                    type="text"
                    className="col-12 col-md-6 col-lg-4"
                  />
                  <ValidatedField
                    label={translate('myApp.regions.description')}
                    id="regions-description"
                    name="description"
                    data-cy="description"
                    type="textarea"
                    className="col-12 col-md-6 col-lg-4"
                  />
                  {/* <ValidatedField
                      label={translate('myApp.regions.regionId')}
                      id="regions-regionId"
                      name="regionId"
                      data-cy="regionId"
                      type="text"
                    /> */}
                  {/* 
                    <ValidatedField
                      label={translate('myApp.regions.imagesCount')}
                      id="regions-imagesCount"
                      name="imagesCount"
                      data-cy="imagesCount"
                      type="text"
                    /> */}
                  {/* <ValidatedField
                      label={translate('myApp.regions.minZoom')}
                      id="regions-minZoom"
                      name="minZoom"
                      data-cy="minZoom"
                      type="text"
                    /> */}
                  {/* <ValidatedField
                      label={translate('myApp.regions.productIdentifer')}
                      id="regions-productIdentifer"
                      name="productIdentifer"
                      data-cy="productIdentifer"
                      type="text"
                    />
                    <ValidatedField
                      label={translate('myApp.regions.isHidden')}
                      id="regions-isHidden"
                      name="isHidden"
                      data-cy="isHidden"
                      type="text"
                    />
                    <ValidatedField
                      label={translate('myApp.regions.isPaid')}
                      id="regions-isPaid"
                      name="isPaid"
                      data-cy="isPaid"
                      check
                      type="checkbox"
                    /> */}
                  <Col xs="12"></Col>
                  <Col xs="auto">
                    <Button
                      // tag={Link}
                      id="cancel-save"
                      data-cy="entityCreateCancelButton"
                      // to={isNew ? `/map-regions` : `/map-regions/${id}`}
                      onClick={() => (isNew ? navigate(-1) : navigate(`/map-regions/${id}`))}
                      className="secondary-theme-btn"
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating} className="theme-btn">
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </Col>
                </ValidatedForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
  // return (
  //   <div>
  //     <h2>{isNew ? 'Create New Region' : 'Edit Region'}</h2>
  //     <Card>
  //       <CardHeader>Region Details</CardHeader>
  //       <CardBody>
  //         <Form>
  //           <FormGroup>
  //             <Label for="coverImage">Cover Image:</Label>
  //             <Input
  //               type="file"
  //               name="coverImage"
  //               id="coverImage"
  //               value={formData.coverImage}
  //               onChange={e => setFormData({ ...formData, coverImage: e.target.value })}
  //             />
  //           </FormGroup>
  //           <FormGroup>
  //             <Label for="regionName">Region Name:</Label>
  //             <Input
  //               type="text"
  //               name="regionName"
  //               id="regionName"
  //               value={formData.regionName}
  //               onChange={e => setFormData({ ...formData, regionName: e.target.value })}
  //             />
  //           </FormGroup>
  //           <Row form>
  //             <Col md={6}>
  //               <FormGroup>
  //                 <Label for="longitude">Longitude:</Label>
  //                 <Input
  //                   type="text"
  //                   name="longitude"
  //                   id="longitude"
  //                   value={formData.longitude}
  //                   onChange={e => setFormData({ ...formData, longitude: e.target.value })}
  //                 />
  //               </FormGroup>
  //             </Col>
  //             <Col md={6}>
  //               <FormGroup>
  //                 <Label for="latitude">Latitude:</Label>
  //                 <Input
  //                   type="text"
  //                   name="latitude"
  //                   id="latitude"
  //                   value={formData.latitude}
  //                   onChange={e => setFormData({ ...formData, latitude: e.target.value })}
  //                 />
  //               </FormGroup>
  //             </Col>
  //           </Row>
  //           <FormGroup>
  //             <Label for="zoomLevel">Zoom Level:</Label>
  //             <Input
  //               type="text"
  //               name="zoomLevel"
  //               id="zoomLevel"
  //               value={formData.zoomLevel}
  //               onChange={e => setFormData({ ...formData, zoomLevel: e.target.value })}
  //             />
  //           </FormGroup>
  //           <FormGroup>
  //             <Label for="description">Description:</Label>
  //             <Input
  //               type="textarea"
  //               name="description"
  //               id="description"
  //               value={formData.description}
  //               onChange={e => setFormData({ ...formData, description: e.target.value })}
  //             />
  //           </FormGroup>
  //           <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/map-regions" replace color="info">
  //             <FontAwesomeIcon icon="arrow-left" />
  //             &nbsp;
  //             <span className="d-none d-md-inline">Cancel</span>
  //           </Button>
  //           &nbsp;
  //           <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" onClick={saveEntity} disabled={updating}>
  //             <FontAwesomeIcon icon="save" />
  //             &nbsp; Save
  //           </Button>
  //         </Form>
  //       </CardBody>
  //     </Card>
  //   </div>
  // );
};

export default RegionsUpdate;
