import { IImages } from 'app/shared/model/images.model';
import { IPosts } from 'app/shared/model/posts.model';

export interface IRegions {
  id?: number;
  latitude?: number | null;
  regionId?: number | null;
  defaultimageUrl?: string | null;
  imagesCount?: number | null;
  longitude?: number | null;
  minZoom?: number | null;
  name?: string | null;
  description?: string | null;
  productIdentifer?: string | null;
  isHidden?: number | null;
  hidden?: number | null;
  isPaid?: boolean | null;
  paid?: boolean | null;
  maxZoom?: number | null;
  images?: IImages[] | null;
  posts?: IPosts[] | null;
}

export const defaultValue: Readonly<IRegions> = {
  isPaid: false,
};
