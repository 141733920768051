import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './users.reducer';

export const UsersDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const usersEntity = useAppSelector(state => state.users.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="usersDetailsHeading">
          <Translate contentKey="myApp.users.detail.title">Users</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{usersEntity.id}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="myApp.users.email">Email</Translate>
            </span>
          </dt>
          <dd>{usersEntity.email}</dd>
          <dt>
            <span id="socialAccessToken">
              <Translate contentKey="myApp.users.socialAccessToken">Social Access Token</Translate>
            </span>
          </dt>
          <dd>{usersEntity.socialAccessToken}</dd>
          <dt>
            <span id="privacyPolicyAccepted">
              <Translate contentKey="myApp.users.privacyPolicyAccepted">Privacy Policy Accepted</Translate>
            </span>
          </dt>
          <dd>{usersEntity.privacyPolicyAccepted ? 'true' : 'false'}</dd>
          <dt>
            <span id="providerId">
              <Translate contentKey="myApp.users.providerId">Provider Id</Translate>
            </span>
          </dt>
          <dd>{usersEntity.providerId}</dd>
          <dt>
            <span id="validateEmailToken">
              <Translate contentKey="myApp.users.validateEmailToken">Validate Email Token</Translate>
            </span>
          </dt>
          <dd>{usersEntity.validateEmailToken}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="myApp.users.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{usersEntity.createdDate}</dd>
          <dt>
            <span id="experiencescount">
              <Translate contentKey="myApp.users.experiencescount">Experiencescount</Translate>
            </span>
          </dt>
          <dd>{usersEntity.experiencescount}</dd>
          <dt>
            <span id="isActivated">
              <Translate contentKey="myApp.users.isActivated">Is Activated</Translate>
            </span>
          </dt>
          <dd>{usersEntity.isActivated ? 'true' : 'false'}</dd>
          <dt>
            <span id="termsAccepted">
              <Translate contentKey="myApp.users.termsAccepted">Terms Accepted</Translate>
            </span>
          </dt>
          <dd>{usersEntity.termsAccepted ? 'true' : 'false'}</dd>
          <dt>
            <span id="isAdmin">
              <Translate contentKey="myApp.users.isAdmin">Is Admin</Translate>
            </span>
          </dt>
          <dd>{usersEntity.isAdmin ? 'true' : 'false'}</dd>
          <dt>
            <span id="likescount">
              <Translate contentKey="myApp.users.likescount">Likescount</Translate>
            </span>
          </dt>
          <dd>{usersEntity.likescount}</dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="myApp.users.modifiedDate">Modified Date</Translate>
            </span>
          </dt>
          <dd>{usersEntity.modifiedDate}</dd>
          <dt>
            <span id="userAccountStatus">
              <Translate contentKey="myApp.users.userAccountStatus">User Account Status</Translate>
            </span>
          </dt>
          <dd>{usersEntity.userAccountStatus ? 'true' : 'false'}</dd>
          <dt>
            <span id="accountDeletionReason">
              <Translate contentKey="myApp.users.accountDeletionReason">Account Deletion Reason</Translate>
            </span>
          </dt>
          <dd>{usersEntity.accountDeletionReason}</dd>
          <dt>
            <span id="userAccessToken">
              <Translate contentKey="myApp.users.userAccessToken">User Access Token</Translate>
            </span>
          </dt>
          <dd>{usersEntity.userAccessToken}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="myApp.users.name">Name</Translate>
            </span>
          </dt>
          <dd>{usersEntity.name}</dd>
          <dt>
            <span id="provider">
              <Translate contentKey="myApp.users.provider">Provider</Translate>
            </span>
          </dt>
          <dd>{usersEntity.provider}</dd>
          <dt>
            <span id="password">
              <Translate contentKey="myApp.users.password">Password</Translate>
            </span>
          </dt>
          <dd>{usersEntity.password}</dd>
          <dt>
            <span id="postscount">
              <Translate contentKey="myApp.users.postscount">Postscount</Translate>
            </span>
          </dt>
          <dd>{usersEntity.postscount}</dd>
          <dt>
            <span id="profileImageUrl">
              <Translate contentKey="myApp.users.profileImageUrl">Profile Image Url</Translate>
            </span>
          </dt>
          <dd>{usersEntity.profileImageUrl}</dd>
          <dt>
            <Translate contentKey="myApp.users.userLikePost">User Like Post</Translate>
          </dt>
          <dd>{usersEntity.userLikePost ? usersEntity.userLikePost.id : ''}</dd>
          <dt>
            <Translate contentKey="myApp.users.blockedUsersTo">Blocked Users To</Translate>
          </dt>
          <dd>{usersEntity.blockedUsersTo ? usersEntity.blockedUsersTo.id : ''}</dd>
          <dt>
            <Translate contentKey="myApp.users.blockedUsersFrom">Blocked Users From</Translate>
          </dt>
          <dd>{usersEntity.blockedUsersFrom ? usersEntity.blockedUsersFrom.id : ''}</dd>
          <dt>
            <Translate contentKey="myApp.users.purchaseInfoTo">Purchase Info To</Translate>
          </dt>
          <dd>{usersEntity.purchaseInfoTo ? usersEntity.purchaseInfoTo.purchaseInfoId : ''}</dd>
          <dt>
            <Translate contentKey="myApp.users.purchaseInfoFrom">Purchase Info From</Translate>
          </dt>
          <dd>{usersEntity.purchaseInfoFrom ? usersEntity.purchaseInfoFrom.purchaseInfoId : ''}</dd>
        </dl>
        <Button tag={Link} to="/users" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/users/${usersEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UsersDetail;
