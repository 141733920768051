import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductIdentifiers from './product-identifiers';
import ProductIdentifiersDetail from './product-identifiers-detail';
import ProductIdentifiersUpdate from './product-identifiers-update';
import ProductIdentifiersDeleteDialog from './product-identifiers-delete-dialog';

const ProductIdentifiersRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductIdentifiers />} />
    <Route path="new" element={<ProductIdentifiersUpdate />} />
    <Route path=":id">
      <Route index element={<ProductIdentifiersDetail />} />
      <Route path="edit" element={<ProductIdentifiersUpdate />} />
      <Route path="delete" element={<ProductIdentifiersDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductIdentifiersRoutes;
