import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BlockedUsers from './blocked-users';
import BlockedUsersDetail from './blocked-users-detail';
import BlockedUsersUpdate from './blocked-users-update';
import BlockedUsersDeleteDialog from './blocked-users-delete-dialog';

const BlockedUsersRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BlockedUsers />} />
    <Route path="new" element={<BlockedUsersUpdate />} />
    <Route path=":id">
      <Route index element={<BlockedUsersDetail />} />
      <Route path="edit" element={<BlockedUsersUpdate />} />
      <Route path="delete" element={<BlockedUsersDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BlockedUsersRoutes;
