export interface IUserDataDownloads {
  id?: number;
  userDataDownloadId?: number | null;
  createdDate?: number | null;
  modifiedDate?: number | null;
  type?: number | null;
  userId?: number | null;
  downloadtypeId?: number | null;
}

export const defaultValue: Readonly<IUserDataDownloads> = {};
