import { INotifications } from 'app/shared/model/notifications.model';
import { IComments } from 'app/shared/model/comments.model';
import { ICommunityImages } from 'app/shared/model/community-images.model';
import { IImages } from 'app/shared/model/images.model';
import { IRegions } from 'app/shared/model/regions.model';

export interface IPosts {
  id?: number;
  postId?: number | null;
  commentsCount?: number | null;
  content?: string | null;
  isArchived?: number | null;
  createdDate?: number | null;
  fkflaggerId?: number | null;
  isFlagged?: number | null;
  isLiked?: number | null;
  likesCount?: number | null;
  location?: number | null;
  fkcommunityimageId?: number | null;
  fkimageId?: number | null;
  fkRegionId?: number | null;
  fkflaggedUserId?: number | null;
  fkUserId?: number | null;
  userId?: number | null;
  commentId?: number | null;
  flagsCount?: number | null;
  notifications?: INotifications[] | null;
  comments?: IComments[] | null;
  communityImages?: ICommunityImages | null;
  images?: IImages | null;
  regions?: IRegions | null;
}

export const defaultValue: Readonly<IPosts> = {};
