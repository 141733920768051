import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Notifications from './notifications';
import NotificationsDetail from './notifications-detail';
import NotificationsUpdate from './notifications-update';
import NotificationsDeleteDialog from './notifications-delete-dialog';

const NotificationsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Notifications />} />
    <Route path="new" element={<NotificationsUpdate />} />
    <Route path=":id">
      <Route index element={<NotificationsDetail />} />
      <Route path="edit" element={<NotificationsUpdate />} />
      <Route path="delete" element={<NotificationsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NotificationsRoutes;
