import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Row, Table } from 'reactstrap';
import { archiveEntity, archivecomments, getEntities, getFlaggedCommentEntities, unFlagComments, unFlagEntity } from './posts.reducer';
import { updateUserEntity } from '../users/users.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from '../../../scrollTop';
const Comment = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const flaggedPostsList = useAppSelector(state => state.posts.entities);
  const loading = useAppSelector(state => state.posts.loading);
  const updating = useAppSelector(state => state.posts.updating);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(getFlaggedCommentEntities({}));
  }, []);

  const nextPage = () => {
    if (currentPage < Math.ceil(flaggedPostsList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <ScrollToTop />
      {loading || updating ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title"> Comment Report Overview</h3>
        <Row className="g-4 align-items-center">
          <Col md="4" lg="6">
            <Button outline disabled>
              Current: <Badge>{flaggedPostsList && flaggedPostsList?.length} comments</Badge>
            </Button>
          </Col>
        </Row>
        <div className="table-responsive mb-3">
          <Table className="table-borderless table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Comment By</th>
                <th>Content</th>
                <th>Date</th>
                <th>Location</th>
                <th>Report By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {flaggedPostsList &&
                flaggedPostsList?.length > 0 &&
                flaggedPostsList?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => {
                  // console.log(item.flaggerInfo, 'item');

                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.content}</td>
                      <td>{moment(item?.createdDate).format('ll')}</td>
                      <td>{item?.region}</td>
                      <td>{item?.flaggerName}</td>
                      <td>
                        <div className="d-flex flex-wrap gap-1">
                          <Button className="theme-btn icon_btn" onClick={() => dispatch(unFlagComments(item))}>
                            <FontAwesomeIcon icon="list-check" />
                          </Button>{' '}
                          <Button className="theme-btn icon_btn" onClick={() => dispatch(archivecomments(item))}>
                            <FontAwesomeIcon icon="times-circle" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <div className="pagination">
          <button className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage}>
            Previous
          </button>
          <button
            className={`page-link ${currentPage === Math.ceil(flaggedPostsList.length / itemsPerPage) ? 'disabled' : ''}`}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default Comment;
