import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUsers } from 'app/shared/model/users.model';
import { getEntities as getUsers } from 'app/entities/users/users.reducer';
import { IImages } from 'app/shared/model/images.model';
import { getEntities as getImages } from 'app/entities/images/images.reducer';
import { IExperiences } from 'app/shared/model/experiences.model';
import { getEntity, updateEntity, createEntity, reset } from './experiences.reducer';

export const ExperiencesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.users.entities);
  const images = useAppSelector(state => state.images.entities);
  const experiencesEntity = useAppSelector(state => state.experiences.entity);
  const loading = useAppSelector(state => state.experiences.loading);
  const updating = useAppSelector(state => state.experiences.updating);
  const updateSuccess = useAppSelector(state => state.experiences.updateSuccess);

  const handleClose = () => {
    navigate('/experiences');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getImages({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...experiencesEntity,
      ...values,
      users: users.find(it => it.id.toString() === values.users.toString()),
      images: images.find(it => it.id.toString() === values.images.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...experiencesEntity,
          users: experiencesEntity?.users?.id,
          images: experiencesEntity?.images?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="myApp.experiences.home.createOrEditLabel" data-cy="ExperiencesCreateUpdateHeading">
            <Translate contentKey="myApp.experiences.home.createOrEditLabel">Create or edit a Experiences</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="experiences-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('myApp.experiences.experienceIid')}
                id="experiences-experienceIid"
                name="experienceIid"
                data-cy="experienceIid"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.experiences.accessibility')}
                id="experiences-accessibility"
                name="accessibility"
                data-cy="accessibility"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.experiences.content')}
                id="experiences-content"
                name="content"
                data-cy="content"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.experiences.createdDate')}
                id="experiences-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.experiences.fkimageId')}
                id="experiences-fkimageId"
                name="fkimageId"
                data-cy="fkimageId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.experiences.fkuserId')}
                id="experiences-fkuserId"
                name="fkuserId"
                data-cy="fkuserId"
                type="text"
              />
              <ValidatedField
                id="experiences-users"
                name="users"
                data-cy="users"
                label={translate('myApp.experiences.users')}
                type="select"
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="experiences-images"
                name="images"
                data-cy="images"
                label={translate('myApp.experiences.images')}
                type="select"
              >
                <option value="" key="0" />
                {images
                  ? images.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/experiences" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ExperiencesUpdate;
