import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardImg, CardTitle, Col, Nav, Row, Table, Input, Badge } from 'reactstrap';
import { byteSize, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IImages } from 'app/shared/model/images.model';
import { deleteEntity, getEntities, getSearchEntities } from './images.reducer';
import Search from 'app/override/components/common/search';
import moment from 'moment';

export const Images = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const imagesList = useAppSelector(state => state.images.entities.content);
  const loading = useAppSelector(state => state.images.loading);

  useEffect(() => {
    dispatch(getEntities({ sort: 'recent' }));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  const deleteHandle = (id: string | number) => {
    dispatch(deleteEntity(id));
  };

  const sortHandle = (event: any) => {
    dispatch(getEntities({ sort: event.target.value }));
  };

  const searchHandle = (value: string) => {
    if (value) {
      dispatch(getSearchEntities(value));
    } else {
      dispatch(getEntities({}));
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title">
          <Translate contentKey="myApp.images.home.title">Create New Region</Translate>
        </h3>

        <div>
          <Search searchHandle={searchHandle} />
        </div>

        <Row className="g-4 align-items-center">
          <Col md="4" lg="6">
            <Button outline disabled>
              Photos <Badge>{imagesList && imagesList.length}</Badge>
            </Button>
          </Col>
          <Col md="8" lg="6" className="d-flex gap-3">
            <Input type="select" onChange={sortHandle}>
              <option value={'recent'}>Most Recent</option>
              <option value={'a-z'}>A - Z</option>
            </Input>
            <Button className="theme-btn py-2 px-3 text-nowrap" tag={Link} to="new">
              Upload New Photos <FontAwesomeIcon icon="upload" />
            </Button>
          </Col>
        </Row>
        <div className="my-4">
          {imagesList && imagesList.length > 0 ? (
            <Row className="g-4">
              {imagesList.map((images: any, i: number) => {
                return (
                  <Col className="mb-2" xs="12" md="6" lg="4" xl="3" key={i}>
                    <Card className="h-100 p-0 shadow-sm rounded-0 border-0">
                      <Nav navbar className="h-100">
                        <CardImg top width="100%" src={images?.url} alt="Sample" />
                        <CardBody className="p-0 d-flex flex-wrap align-items-stretch">
                          <CardTitle className="py-2 px-2 align-self-start w-100" tag="h5">
                            {images?.name}
                          </CardTitle>
                          <CardSubtitle className="mb-0 mt-2 py-2 px-2 text-muted align-self-end w-100" tag="h6">
                            {/* {images?.albumAddDate} */}
                            <div className="">{moment(images?.createdDate).format('LL')}</div>

                            <div className="card-icons d-flex gap-2">
                              <Button onClick={() => navigate(`${images.imageId}`)}>
                                <FontAwesomeIcon icon="pencil-alt" />
                              </Button>
                              {/* <Button onClick={() => deleteHandle(images.imageId)}> */}
                              <Button onClick={() => navigate(`${images.imageId}/delete`)}>
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </CardSubtitle>
                        </CardBody>
                      </Nav>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="myApp.images.home.notFound">No Images found</Translate>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Images;
