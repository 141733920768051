export interface IUserDevices {
  id?: number;
  userDeviceId?: number | null;
  createdDate?: number | null;
  deviceToken?: string | null;
  deviceType?: number | null;
  modifiedDate?: number | null;
  userprofileId?: number | null;
}

export const defaultValue: Readonly<IUserDevices> = {};
