import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IImages, defaultValue } from 'app/shared/model/images.model';

const initialState: EntityState<IImages> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'anseladams/api/posts/liked';
// const apiUrl1 = 'anseladams/downloadImage';
// Actions

export const getEntities = createAsyncThunk('favourites/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  // const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrl}`;
  let favourites = await axios.get<IImages[]>(requestUrl);
  console.log(favourites, favourites);
  return favourites.data;
});

export const getSearchEntities = createAsyncThunk('regions/fetch_entity_list', async (value: string) => {
  const requestUrl = `${apiUrl}/${value}/search`;
  // return axios.get<IImages[]>(requestUrl);
  let favourites = await axios.get<IImages[]>(requestUrl);
  console.log(favourites, favourites);
  return favourites.data;
});

export const downloadImage = createAsyncThunk(
  'favourites/download_image',
  async (imageUrl: string) => {
    try {
      // Define the API endpoint and pass the imageUrl as a query parameter
      const requestUrl = `anseladams/downloadImage?url=${imageUrl}`;

      // Use Axios to send a GET request to the server
      const response = await axios.get(requestUrl, {
        responseType: 'blob', // This specifies that the response is a binary blob (image)
      });

      return response.data; // Return the binary image data
    } catch (error) {
      throw error;
    }
  },
  { serializeError: serializeAxiosError }
);

// export const getEntities = createAsyncThunk('favourites/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
//   // const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
//   const requestUrl = `${apiUrl}`;
//   let Favourites = await axios.get<IImages[]>(requestUrl);
//   console.log(Favourites.data, 'datadata');
//   return Favourites.data;
// });

export const getEntity = createAsyncThunk(
  'favourites/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IImages>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'favourites/create_entity',
  async (entity: IImages, thunkAPI) => {
    const result = await axios.post<IImages>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'favourites/update_entity',
  async (entity: IImages, thunkAPI) => {
    const result = await axios.put<IImages>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'favourites/partial_update_entity',
  async (entity: IImages, thunkAPI) => {
    const result = await axios.patch<IImages>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'favourites/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IImages>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const FavouriteSlice = createEntitySlice({
  name: 'favourites',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(downloadImage.pending, state => {
        state.loading = true;
      })
      .addCase(downloadImage.fulfilled, state => {
        state.loading = false;
      })
      .addCase(downloadImage.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = FavouriteSlice.actions;

// Reducer
export default FavouriteSlice.reducer;
