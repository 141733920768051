import images from 'app/entities/images/images.reducer';
import users from 'app/entities/users/users.reducer';
import albums from 'app/entities/albums/albums.reducer';
import experiences from 'app/entities/experiences/experiences.reducer';
import deletedUserOtp from 'app/entities/deleted-user-otp/deleted-user-otp.reducer';
import locations from 'app/entities/locations/locations.reducer';
import comments from 'app/entities/comments/comments.reducer';
import purchaseInfo from 'app/entities/purchase-info/purchase-info.reducer';
import userDataShares from 'app/entities/user-data-shares/user-data-shares.reducer';
import frontImage from 'app/entities/front-image/front-image.reducer';
import userDataDownloads from 'app/entities/user-data-downloads/user-data-downloads.reducer';
import communityImages from 'app/entities/community-images/community-images.reducer';
import flaggedPosts from 'app/entities/flagged-posts/flagged-posts.reducer';
import regions from 'app/entities/regions/regions.reducer';
import flaggedReasons from 'app/entities/flagged-reasons/flagged-reasons.reducer';
import blockedUsers from 'app/entities/blocked-users/blocked-users.reducer';
import productIdentifiers from 'app/entities/product-identifiers/product-identifiers.reducer';
import posts from 'app/entities/posts/posts.reducer';
import userDevices from 'app/entities/user-devices/user-devices.reducer';
import userLikePost from 'app/entities/user-like-post/user-like-post.reducer';
import notifications from 'app/entities/notifications/notifications.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  images,
  users,
  albums,
  experiences,
  deletedUserOtp,
  locations,
  comments,
  purchaseInfo,
  userDataShares,
  frontImage,
  userDataDownloads,
  communityImages,
  flaggedPosts,
  regions,
  flaggedReasons,
  blockedUsers,
  productIdentifiers,
  posts,
  userDevices,
  userLikePost,
  notifications,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
