import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Row, Table } from 'reactstrap';
import {
  archiveEntity,
  archivecomments,
  getEntities,
  getFlaggedCommentEntities,
  getArchiveCommentsEntities,
  unArchiveEntity,
  unFlagComments,
  unFlagEntity,
  unarchivecommentsEntity,
} from './posts.reducer';
import { updateUserEntity } from '../users/users.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
// import DummyImage from "../../assets/images/default.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from '../../../scrollTop';

const ArchivedComment = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const archivePostsList = useAppSelector(state => state.posts.entities);
  const loading = useAppSelector(state => state.posts.loading);
  const updating = useAppSelector(state => state.posts.updating);

  useEffect(() => {
    dispatch(getArchiveCommentsEntities({}));
  }, []);

  return (
    <>
      <ScrollToTop />
      {loading || updating ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title"> Archived Comment Overview</h3>
        <Row className="g-4 align-items-center">
          <Col md="4" lg="6">
            <Button outline disabled>
              Current: <Badge>{archivePostsList && archivePostsList?.length} Archived</Badge>
            </Button>
          </Col>
        </Row>
        <Table className="table-borderless table-striped table-hover">
          <thead className="table-dark">
            <tr>
              <th>Comment By</th>
              <th>Content</th>
              <th>Date</th>
              <th>Report By</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {archivePostsList &&
              archivePostsList?.length > 0 &&
              archivePostsList?.map((item, index) => {
                // console.log(item.flaggerInfo, 'item');

                return (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.content}</td>
                    <td>{moment(item?.createdDate).format('ll')}</td>
                    <td>{item?.flaggerName}</td>
                    <td>
                      <Button className="theme-btn py-2 px-3 text-nowrap" onClick={() => dispatch(unarchivecommentsEntity(item))}>
                        Restore
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default ArchivedComment;
