import axios, { AxiosResponse } from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { useNavigate } from 'react-router-dom';
import { json } from 'body-parser';
import { log } from 'console';

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

// const navigate = useNavigate();

export const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession =
  (auth: IAuthParams): AppThunk =>
  async (dispatch, getState) => {
    await dispatch(getAccount(auth));

    const { account } = getState().authentication;
    if (account && account.langKey) {
      const langKey = Storage.session.get('locale', account.langKey);
      await dispatch(setLocale(langKey));
    }
  };

export const getAccount = createAsyncThunk(
  'authentication/get_account',
  async (auth: IAuthParams) =>
    axios.post<any>(
      'anseladams/users/authentication',
      {},
      {
        headers: {
          Email: auth.username,
          // Email: "gurpreet.kaur@redblink.net",
          // Password: "123ready"
          Password: auth.password,
        },
      }
    ),
  {
    serializeError: serializeAxiosError,
  }
);

interface IAuthParams {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (auth: IAuthParams) =>
    axios.post<any>('anseladams/users/authentication', auth, {
      headers: {
        Email: auth.username,
        // Email: "gurpreet.kaur@redblink.net",
        // Password: "123ready"
        Password: auth.password,
      },
    }),
  {
    serializeError: serializeAxiosError,
  }
);

export const login: (username: string, password: string, rememberMe?: boolean) => AppThunk =
  (username, password, rememberMe = false) =>
  async dispatch => {
    // const navigate = useNavigate();
    const result: any = await dispatch(authenticate({ username, password, rememberMe }));

    if (!result.error) {
      const response = result.payload as AxiosResponse;

      const bearerToken = response?.data?.userAccessToken;
      if (bearerToken) {
        // const jwt = bearerToken.slice(7, bearerToken.length);
        const jwt = bearerToken;

        let userData = { username, password };
        // navigate('/map-regions');

        if (rememberMe) {
          localStorage.setItem('user', JSON.stringify(userData));
          Storage.local.set(AUTH_TOKEN_KEY, jwt);
        } else {
          Storage.session.set(AUTH_TOKEN_KEY, jwt);
          sessionStorage.setItem('user', JSON.stringify(userData));
        }
      }
      window.location.href = '/map-regions';
      // dispatch(getSession({ username, password }));
      // navigate("/map-regions");
    }
  };

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => AppThunk = () => dispatch => {
  clearAuthToken();
  dispatch(logoutSession());
  localStorage.removeItem('user');
  sessionStorage.removeItem('user');
};

export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    logoutSession() {
      return {
        ...initialState,
        showModalLogin: true,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        showModalLogin: true,
        loginError: true,
      }))
      .addCase(authenticate.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      }))
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(authenticate.pending, state => {
        state.loading = true;
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { logoutSession, authError, clearAuth } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
