import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-devices.reducer';

export const UserDevicesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userDevicesEntity = useAppSelector(state => state.userDevices.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userDevicesDetailsHeading">
          <Translate contentKey="myApp.userDevices.detail.title">UserDevices</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userDevicesEntity.id}</dd>
          <dt>
            <span id="userDeviceId">
              <Translate contentKey="myApp.userDevices.userDeviceId">User Device Id</Translate>
            </span>
          </dt>
          <dd>{userDevicesEntity.userDeviceId}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="myApp.userDevices.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{userDevicesEntity.createdDate}</dd>
          <dt>
            <span id="deviceToken">
              <Translate contentKey="myApp.userDevices.deviceToken">Device Token</Translate>
            </span>
          </dt>
          <dd>{userDevicesEntity.deviceToken}</dd>
          <dt>
            <span id="deviceType">
              <Translate contentKey="myApp.userDevices.deviceType">Device Type</Translate>
            </span>
          </dt>
          <dd>{userDevicesEntity.deviceType}</dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="myApp.userDevices.modifiedDate">Modified Date</Translate>
            </span>
          </dt>
          <dd>{userDevicesEntity.modifiedDate}</dd>
          <dt>
            <span id="userprofileId">
              <Translate contentKey="myApp.userDevices.userprofileId">Userprofile Id</Translate>
            </span>
          </dt>
          <dd>{userDevicesEntity.userprofileId}</dd>
        </dl>
        <Button tag={Link} to="/user-devices" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-devices/${userDevicesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserDevicesDetail;
