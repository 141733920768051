import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserDevices from './user-devices';
import UserDevicesDetail from './user-devices-detail';
import UserDevicesUpdate from './user-devices-update';
import UserDevicesDeleteDialog from './user-devices-delete-dialog';

const UserDevicesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserDevices />} />
    <Route path="new" element={<UserDevicesUpdate />} />
    <Route path=":id">
      <Route index element={<UserDevicesDetail />} />
      <Route path="edit" element={<UserDevicesUpdate />} />
      <Route path="delete" element={<UserDevicesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserDevicesRoutes;
