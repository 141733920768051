import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faPaperPlane, faQuestion, faImage, faCopy } from '@fortawesome/free-solid-svg-icons';
import { NavItem, NavLink, Nav } from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import SubMenu from './SubMenu';

const submenus = [
  [
    {
      title: 'Map Regions',
      target: 'map-regions',
    },
    {
      title: 'Products',
      target: 'map-regions/products',
    },
  ],
  [
    {
      title: 'Albums',
      target: 'albums',
    },
    {
      title: 'Products',
      target: 'albums/products',
    },
  ],
  [
    {
      title: 'Photos',
      target: 'report',
    },
    {
      title: 'Comment',
      target: 'report/currentComment',
    },
    {
      title: 'ArchivedPost',
      target: 'report/archived',
    },
    {
      title: 'ArchivedComment',
      target: 'report/archiveComment',
    },
    {
      title: 'Users',
      target: 'report/user',
    },
  ],
];

const SideBar = () => (
  <div className="h-100">
    {/* <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "#fff" }}>
        &times;
      </span>
      <h3>Bootstrap Sidebar</h3>
    </div> */}
    <div className="side-menu h-100">
      <Nav vertical className="list-unstyled pb-3">
        <SubMenu title="Map Regions" icon={faHome} items={submenus[0]} />
        <SubMenu title="Albums" icon={faHome} items={submenus[1]} />
        <NavItem>
          <NavLink tag={Link} to={'/all-photos'}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            All Photos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={'/liked'}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            Favourites
          </NavLink>
        </NavItem>
        <SubMenu title="Report" icon={faStar} items={submenus[2]} />
        <NavItem>
          <NavLink tag={Link} to={'/userconsent'}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            User-Consent
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={'/user-data'}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            User-Data
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={'/unactivated-user'}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            Inactive User
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  </div>
);

export default SideBar;
