import { useAppDispatch } from 'app/config/store';
import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { hideHandle, paidHandle } from './regions.reducer';

const MapRegionSwitch = ({ data, type, setLoading }) => {
  const [entityData, setEntityData] = useState(data);
  const dispatch = useAppDispatch();

  const handleSwitchChange = async (e: any) => {
    setLoading(true);
    setEntityData(prevValues => {
      return {
        ...prevValues,
        hidden: !e.target.checked,
      };
    });
    await dispatch(hideHandle({ ...entityData, hidden: !e.target.checked }));
    setLoading(false);
  };

  const handleCheckboxChange = async (e: any) => {
    setLoading(true);
    setEntityData(prevValues => {
      return {
        ...prevValues,
        paid: e.target.checked,
      };
    });
    await dispatch(paidHandle({ ...entityData, paid: e.target.checked }));
    setLoading(false);
  };

  return (
    <>
      {type == 'switch' ? (
        <>
          <FormGroup switch>
            <Input type={'switch'} checked={!entityData?.hidden} onChange={handleSwitchChange} role={'switch'} />
          </FormGroup>
        </>
      ) : (
        <>
          <Input type="checkbox" checked={entityData?.paid} onChange={handleCheckboxChange} />
          {entityData?.paid ? <div> Paid </div> : <div> Free </div>}
        </>
      )}
    </>
  );
};
export default MapRegionSwitch;
