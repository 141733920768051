import { IPosts } from 'app/shared/model/posts.model';

export interface INotifications {
  id?: number;
  notificationId?: number | null;
  createdDate?: string | null;
  type?: string | null;
  postId?: number | null;
  userId?: number | null;
  actionbyUserId?: number | null;
  postedbyUserId?: number | null;
  posts?: IPosts | null;
}

export const defaultValue: Readonly<INotifications> = {};
