import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Row, Table } from 'reactstrap';
import {
  archiveEntity,
  archivecomments,
  getArchivePostsEntities,
  getEntities,
  getFlaggedCommentEntities,
  unArchiveEntity,
  unFlagComments,
  unFlagEntity,
} from './posts.reducer';
import { updateUserEntity } from '../users/users.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from '../../../scrollTop';

const ArchivedPost = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const archivePostsList = useAppSelector(state => state.posts.entities);
  const loading = useAppSelector(state => state.posts.loading);
  const updating = useAppSelector(state => state.posts.updating);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(getArchivePostsEntities({}));
  }, []);

  const nextPage = () => {
    if (currentPage < Math.ceil(archivePostsList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <ScrollToTop />
      {loading || updating ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title"> Archived Overview</h3>
        <Row className="g-4 align-items-center">
          <Col md="4" lg="6">
            <Button outline disabled>
              Current: <Badge>{archivePostsList && archivePostsList?.length} Archived</Badge>
            </Button>
          </Col>
        </Row>
        <div className="table-responsive mb-3">
          <Table className="table-borderless table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Photo</th>
                <th>Post By</th>
                <th>Reason</th>
                <th>#OF FLAG</th>
                <th>Date</th>
                <th>Report By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {archivePostsList &&
                archivePostsList?.length > 0 &&
                archivePostsList?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => {
                  // console.log(item.flaggerInfo, 'item');

                  return (
                    <tr key={index}>
                      <td>
                        <img src={item?.communityImage?.url && item?.communityImage?.url} className="img-thumbnail" />
                      </td>
                      <td>{item?.name}</td>
                      <td>{'appropriate'}</td>
                      <td>{item?.flagsCount}</td>
                      <td>{moment(item?.createdDate).format('ll')}</td>
                      <td>{item?.flaggerName}</td>
                      <td>
                        <Button className="theme-btn py-2 px-3 text-nowrap" onClick={() => dispatch(unArchiveEntity(item))}>
                          Restore
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <div className="pagination">
          <button className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage}>
            Previous
          </button>
          <button
            className={`page-link ${currentPage === Math.ceil(archivePostsList.length / itemsPerPage) ? 'disabled' : ''}`}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default ArchivedPost;
