import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IExperiences } from 'app/shared/model/experiences.model';
import { getEntities as getExperiences } from 'app/entities/experiences/experiences.reducer';
import { IUserLikePost } from 'app/shared/model/user-like-post.model';
import { getEntities as getUserLikePosts } from 'app/entities/user-like-post/user-like-post.reducer';
import { IBlockedUsers } from 'app/shared/model/blocked-users.model';
import { getEntities as getBlockedUsers } from 'app/entities/blocked-users/blocked-users.reducer';
import { IPurchaseInfo } from 'app/shared/model/purchase-info.model';
import { getEntities as getPurchaseInfos } from 'app/entities/purchase-info/purchase-info.reducer';
import { IUsers } from 'app/shared/model/users.model';
import { getEntity, updateEntity, createEntity, reset } from './users.reducer';

export const UsersUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const experiences = useAppSelector(state => state.experiences.entities);
  const userLikePosts = useAppSelector(state => state.userLikePost.entities);
  const blockedUsers = useAppSelector(state => state.blockedUsers.entities);
  const purchaseInfos = useAppSelector(state => state.purchaseInfo.entities);
  const usersEntity = useAppSelector(state => state.users.entity);
  const loading = useAppSelector(state => state.users.loading);
  const updating = useAppSelector(state => state.users.updating);
  const updateSuccess = useAppSelector(state => state.users.updateSuccess);

  const handleClose = () => {
    navigate('/users');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getExperiences({}));
    dispatch(getUserLikePosts({}));
    dispatch(getBlockedUsers({}));
    dispatch(getPurchaseInfos({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...usersEntity,
      ...values,
      userLikePost: userLikePosts.find(it => it.id.toString() === values.userLikePost.toString()),
      blockedUsersTo: blockedUsers.find(it => it.id.toString() === values.blockedUsersTo.toString()),
      blockedUsersFrom: blockedUsers.find(it => it.id.toString() === values.blockedUsersFrom.toString()),
      purchaseInfoTo: purchaseInfos.find(it => it.id.toString() === values.purchaseInfoTo.toString()),
      purchaseInfoFrom: purchaseInfos.find(it => it.id.toString() === values.purchaseInfoFrom.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...usersEntity,
          userLikePost: usersEntity?.userLikePost?.id,
          blockedUsersTo: usersEntity?.blockedUsersTo?.id,
          blockedUsersFrom: usersEntity?.blockedUsersFrom?.id,
          purchaseInfoTo: usersEntity?.purchaseInfoTo?.id,
          purchaseInfoFrom: usersEntity?.purchaseInfoFrom?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="myApp.users.home.createOrEditLabel" data-cy="UsersCreateUpdateHeading">
            <Translate contentKey="myApp.users.home.createOrEditLabel">Create or edit a Users</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="users-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('myApp.users.email')} id="users-email" name="email" data-cy="email" type="text" />
              <ValidatedField
                label={translate('myApp.users.socialAccessToken')}
                id="users-socialAccessToken"
                name="socialAccessToken"
                data-cy="socialAccessToken"
                type="textarea"
              />
              <ValidatedField
                label={translate('myApp.users.privacyPolicyAccepted')}
                id="users-privacyPolicyAccepted"
                name="privacyPolicyAccepted"
                data-cy="privacyPolicyAccepted"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('myApp.users.providerId')}
                id="users-providerId"
                name="providerId"
                data-cy="providerId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.validateEmailToken')}
                id="users-validateEmailToken"
                name="validateEmailToken"
                data-cy="validateEmailToken"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.createdDate')}
                id="users-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.experiencescount')}
                id="users-experiencescount"
                name="experiencescount"
                data-cy="experiencescount"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.isActivated')}
                id="users-isActivated"
                name="isActivated"
                data-cy="isActivated"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('myApp.users.termsAccepted')}
                id="users-termsAccepted"
                name="termsAccepted"
                data-cy="termsAccepted"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('myApp.users.isAdmin')}
                id="users-isAdmin"
                name="isAdmin"
                data-cy="isAdmin"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('myApp.users.likescount')}
                id="users-likescount"
                name="likescount"
                data-cy="likescount"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.modifiedDate')}
                id="users-modifiedDate"
                name="modifiedDate"
                data-cy="modifiedDate"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.userAccountStatus')}
                id="users-userAccountStatus"
                name="userAccountStatus"
                data-cy="userAccountStatus"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('myApp.users.accountDeletionReason')}
                id="users-accountDeletionReason"
                name="accountDeletionReason"
                data-cy="accountDeletionReason"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.userAccessToken')}
                id="users-userAccessToken"
                name="userAccessToken"
                data-cy="userAccessToken"
                type="text"
              />
              <ValidatedField label={translate('myApp.users.name')} id="users-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                label={translate('myApp.users.provider')}
                id="users-provider"
                name="provider"
                data-cy="provider"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.password')}
                id="users-password"
                name="password"
                data-cy="password"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.postscount')}
                id="users-postscount"
                name="postscount"
                data-cy="postscount"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.users.profileImageUrl')}
                id="users-profileImageUrl"
                name="profileImageUrl"
                data-cy="profileImageUrl"
                type="textarea"
              />
              <ValidatedField
                id="users-userLikePost"
                name="userLikePost"
                data-cy="userLikePost"
                label={translate('myApp.users.userLikePost')}
                type="select"
              >
                <option value="" key="0" />
                {userLikePosts
                  ? userLikePosts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="users-blockedUsersTo"
                name="blockedUsersTo"
                data-cy="blockedUsersTo"
                label={translate('myApp.users.blockedUsersTo')}
                type="select"
              >
                <option value="" key="0" />
                {blockedUsers
                  ? blockedUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="users-blockedUsersFrom"
                name="blockedUsersFrom"
                data-cy="blockedUsersFrom"
                label={translate('myApp.users.blockedUsersFrom')}
                type="select"
              >
                <option value="" key="0" />
                {blockedUsers
                  ? blockedUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="users-purchaseInfoTo"
                name="purchaseInfoTo"
                data-cy="purchaseInfoTo"
                label={translate('myApp.users.purchaseInfoTo')}
                type="select"
              >
                <option value="" key="0" />
                {purchaseInfos
                  ? purchaseInfos.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.purchaseInfoId}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="users-purchaseInfoFrom"
                name="purchaseInfoFrom"
                data-cy="purchaseInfoFrom"
                label={translate('myApp.users.purchaseInfoFrom')}
                type="select"
              >
                <option value="" key="0" />
                {purchaseInfos
                  ? purchaseInfos.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.purchaseInfoId}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/users" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UsersUpdate;
