import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './product-identifiers.reducer';

export const ProductIdentifiersDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const productIdentifiersEntity = useAppSelector(state => state.productIdentifiers.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="productIdentifiersDetailsHeading">
          <Translate contentKey="myApp.productIdentifiers.detail.title">ProductIdentifiers</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{productIdentifiersEntity.id}</dd>
          <dt>
            <span id="productIdentifierId">
              <Translate contentKey="myApp.productIdentifiers.productIdentifierId">Product Identifier Id</Translate>
            </span>
          </dt>
          <dd>{productIdentifiersEntity.productIdentifierId}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="myApp.productIdentifiers.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{productIdentifiersEntity.createdDate}</dd>
          <dt>
            <span id="identifier">
              <Translate contentKey="myApp.productIdentifiers.identifier">Identifier</Translate>
            </span>
          </dt>
          <dd>{productIdentifiersEntity.identifier}</dd>
          <dt>
            <span id="storeType">
              <Translate contentKey="myApp.productIdentifiers.storeType">Store Type</Translate>
            </span>
          </dt>
          <dd>{productIdentifiersEntity.storeType}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="myApp.productIdentifiers.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{productIdentifiersEntity.legacyId}</dd>
          <dt>
            <span id="productType">
              <Translate contentKey="myApp.productIdentifiers.productType">Product Type</Translate>
            </span>
          </dt>
          <dd>{productIdentifiersEntity.productType}</dd>
          <dt>
            <Translate contentKey="myApp.productIdentifiers.purchaseInfo">Purchase Info</Translate>
          </dt>
          <dd>{productIdentifiersEntity.purchaseInfo ? productIdentifiersEntity.purchaseInfo.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/product-identifiers" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/product-identifiers/${productIdentifiersEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProductIdentifiersDetail;
