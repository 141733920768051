import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PurchaseInfo from './purchase-info';
import PurchaseInfoDetail from './purchase-info-detail';
import PurchaseInfoUpdate from './purchase-info-update';
import PurchaseInfoDeleteDialog from './purchase-info-delete-dialog';

const PurchaseInfoRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PurchaseInfo />} />
    <Route path="new" element={<PurchaseInfoUpdate />} />
    <Route path=":id">
      <Route index element={<PurchaseInfoDetail />} />
      <Route path="edit" element={<PurchaseInfoUpdate />} />
      <Route path="delete" element={<PurchaseInfoDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PurchaseInfoRoutes;
