import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Regions from './regions';
import RegionsDetail from './regions-detail';
import RegionsUpdate from './regions-update';
import RegionsDeleteDialog from './regions-delete-dialog';
import Products from './products';

const RegionsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Regions />} />
    <Route path="products" element={<Products />} />
    <Route path="new" element={<RegionsUpdate />} />
    <Route path=":id">
      {/* <Route index element={<RegionsUpdate />} /> */}
      <Route index element={<RegionsDetail />} />
      <Route path="edit" element={<RegionsUpdate />} />
      <Route path="delete" element={<RegionsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RegionsRoutes;
