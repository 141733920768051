import React, { useEffect, useState } from 'react';
import { Button, Table, UncontrolledTooltip, Badge, Col, Row } from 'reactstrap';
import { archiveEntity, getEntities, unFlagEntity } from './posts.reducer';
import { updateUserEntity } from '../users/users.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from 'app/override/components/common/search';
import { getSearchEntities } from './posts.reducer';
import ScrollToTop from '../../../scrollTop';

const Photos = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const flaggedPostsList = useAppSelector(state => state.posts.entities);
  const loading = useAppSelector(state => state.posts.loading);
  const updating = useAppSelector(state => state.posts.updating);
  const userUpdating = useAppSelector(state => state.users.updating);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const updateUserHandle = async (id: Number) => {
    await dispatch(updateUserEntity(id));
    await dispatch(getEntities({}));
  };

  const searchHandle = (value: string) => {
    if (value) {
      dispatch(getSearchEntities(value));
    } else {
      dispatch(getEntities({}));
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = flaggedPostsList.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < Math.ceil(flaggedPostsList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <ScrollToTop />
      {loading || updating || userUpdating ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title">
          {' '}
          <span className="text-wrap">Report Overview (Photo is not currently visible) / (Comment is not currently visible)</span>
        </h3>
        <div>
          <Search searchHandle={searchHandle} />
        </div>
        <Row className="g-4 align-items-center">
          <Col md="4" lg="6">
            <Button outline disabled>
              Current: <Badge>{flaggedPostsList && flaggedPostsList?.length} reports</Badge>
            </Button>
          </Col>
        </Row>
        <div className="table-responsive mb-3">
          <Table className="table-borderless table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Photo</th>
                <th>Reference Image</th>
                <th>Post By</th>
                <th>Region</th>
                <th>#OF FLAG</th>
                <th>Date</th>
                <th>Report By : Reason</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems?.length > 0 &&
                currentItems?.map((item, index) => {
                  // console.log(item.flaggerInfo, 'item');

                  return (
                    <>
                      <tr key={index}>
                        <td>
                          <img src={item?.communityImage?.url} className="img-thumbnail" />
                        </td>
                        <td>
                          <img src={item?.referenceImageUrl} className="img-thumbnail" />
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.regionName}</td>
                        <td>{item?.flagsCount}</td>
                        <td>{moment(item?.createdDate).format('ll')}</td>
                        <td>
                          {item?.flaggerInfo &&
                            Object.keys(item?.flaggerInfo)
                              .map((flag, index) => {
                                return item?.flaggerInfo[flag];
                              })
                              .join(', ')}
                        </td>
                        <td>
                          <div className="d-flex flex-wrap gap-1">
                            <Button id="appropriateBtn" className="theme-btn icon_btn" onClick={() => dispatch(unFlagEntity(item))}>
                              <FontAwesomeIcon icon="list-check" />
                              <UncontrolledTooltip placement="top" target="appropriateBtn">
                                Appropriate
                              </UncontrolledTooltip>
                            </Button>
                            <Button id="InappropriateBtn" className="theme-btn icon_btn" onClick={() => dispatch(archiveEntity(item))}>
                              <FontAwesomeIcon icon="times-circle" />
                              <UncontrolledTooltip placement="top" target="InappropriateBtn">
                                Inappropriate
                              </UncontrolledTooltip>
                            </Button>
                            <Button id="DeleteBtn" className="theme-btn icon_btn" onClick={() => updateUserHandle(item?.userId)}>
                              <FontAwesomeIcon icon="trash" />
                              <UncontrolledTooltip placement="top" target="DeleteBtn">
                                Delete User
                              </UncontrolledTooltip>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <div className="pagination">
          <button className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage}>
            Previous
          </button>
          <button
            className={`page-link ${currentPage === Math.ceil(flaggedPostsList.length / itemsPerPage) ? 'disabled' : ''}`}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default Photos;
