import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';
import { byteSize, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import moment from 'moment';
import { getEntities } from './users.reducer';

export const Users = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const usersList = useAppSelector(state => state.users.entities);
  const loading = useAppSelector(state => state.users.loading);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  // Check if usersList is undefined before attempting to use slice
  const currentItems = usersList ? usersList.slice(indexOfFirstItem, indexOfLastItem) : [];
  const isLastPage = usersList && currentPage === Math.ceil((usersList.length || 0) / itemsPerPage);
  const hasNextPage = usersList && currentPage < Math.ceil((usersList.length || 0) / itemsPerPage);

  const nextPage = () => {
    if (usersList && currentPage < Math.ceil((usersList.length || 0) / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (usersList && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title"> User Consent</h3>
        <div className="table-responsive mb-3">
          <Table className="table-borderless table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date</th>
                <th>Consent</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((item, index) => {
                  const nameParts = item.name.split(' ');
                  const firstName = nameParts[0];
                  const lastName = nameParts[1];
                  console.log(item, 'item');

                  return (
                    <tr key={index}>
                      <td>{item.email}</td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{moment(item?.modifiedDate).format('L')}</td>
                      <td>{item.termsAccepted ? <Badge color="success">Accepted</Badge> : <Badge color="danger">Declined</Badge>}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <div className="pagination">
          <button className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <button className={`page-link ${isLastPage ? 'disabled' : ''}`} onClick={nextPage} disabled={isLastPage}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Users;
