import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  CardImg,
  CardTitle,
  CardSubtitle,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Input,
  ModalFooter,
} from 'reactstrap';
import { Translate, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, getEntityImages, updateEntityImages } from './regions.reducer';
import moment from 'moment';
import { getEntities } from '../images/images.reducer';

export const RegionsDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const regionsEntity = useAppSelector(state => state.regions.entity);
  const loading = useAppSelector(state => state.regions.loading);
  const [imageLoading, setImageLoading] = useState(true);
  const [regionImages, setRegionImages] = useState(undefined);
  const { id } = useParams<'id'>();

  const [imagesModal, setImagesModal] = useState(false);
  const imagesList = useAppSelector(state => state.images.entities.content);
  const modalLoading = useAppSelector(state => state.images.loading);
  const [imagerray, setImagerray] = useState([]);

  const imagesModalHandle = () => {
    setImagesModal(!imagesModal);
    // dispatch(getEntities({ sort: 'recent' }));
  };

  useEffect(() => {
    if (imagesModal) {
      dispatch(getEntities({ sort: 'recent' }));
    }
  }, [imagesModal]);

  useEffect(() => {
    (async () => {
      await dispatch(getEntity(id));
      const { payload } = await dispatch(getEntityImages(id));
      let data: any = payload;
      setRegionImages(data);
      setImageLoading(false);
    })();
  }, []);

  const imageHandle = ({ target }) => {
    if (target.checked) {
      let temparray = [...imagerray, target.value];
      setImagerray(temparray);
    } else {
      let temparray = [...imagerray];
      const index = temparray.indexOf(target.value);
      if (index > -1) {
        // only splice array when item is found
        temparray.splice(index, 1); // 2nd parameter means remove one item only
      }
      setImagerray(temparray);
    }
  };

  const addImagesHandle = async () => {
    const temparray = imagerray.map(item => {
      return parseInt(item);
    });

    let data = {
      regionId: parseInt(id),
      images: [...temparray],
    };
    await dispatch(updateEntityImages(data));
    imagesModalHandle();
  };

  return (
    <>
      {loading || imageLoading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <Row className="justify-content-between">
        <Col md="12">
          <h3 data-cy="regionsDetailsHeading" className="page-title">
            {regionsEntity?.name}
          </h3>
        </Col>
      </Row>

      <Card>
        <Row className="g-2 mb-2 justify-content-between align-items-center">
          <Col md="auto">
            <Button outline disabled>
              Photos <Badge>{regionsEntity.imagesCount}</Badge>
            </Button>
          </Col>
          <Col md="auto" className="d-flex gap-3">
            <Button className="theme-btn" onClick={imagesModalHandle}>
              <FontAwesomeIcon icon="plus" /> Add Photos
            </Button>
            <Modal isOpen={imagesModal} toggle={imagesModalHandle}>
              <ModalHeader toggle={imagesModalHandle}>Map Gallery</ModalHeader>
              <ModalBody className="map-gallery ">
                <Row className="g-3">
                  {modalLoading ? <Spinner /> : ''}
                  {imagesList &&
                    imagesList.length > 0 &&
                    imagesList.map((item, index) => {
                      return (
                        <Col md="3" className="position-relative">
                          <Input className="position-absolute ms-2 mt-2" type="checkbox" value={item?.imageId} onChange={imageHandle} />
                          <img src={item?.url} className="select-img" />
                        </Col>
                      );
                    })}
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button className="theme-btn" color="primary" onClick={addImagesHandle}>
                  Save
                </Button>{' '}
                <Button className="secondary-theme-btn" color="secondary" onClick={imagesModalHandle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <Button className="secondary-theme-btn" tag={Link} to={`edit`} replace>
              <FontAwesomeIcon icon="pencil-alt" />
              &nbsp;
              <span className="d-none d-md-inline ms-1">
                <Translate contentKey="entity.action.edit"> Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
        <CardHeader className="fs-5 fw-bold border mt-2">
          Description: {regionsEntity.description ? regionsEntity.description : 'N/A'}
        </CardHeader>
        <CardBody className="px-0">
          {regionImages && regionImages.length > 0 ? (
            <Row className="g-4">
              {regionImages.map((images, i) => {
                return (
                  <Col className="mb-2" xs="12" md="6" lg="4" xl="3" key={i}>
                    <Card className="h-100 p-0 shadow-sm rounded-0 border-0">
                      <Nav navbar className="h-100">
                        <CardImg top width="100%" src={images?.url} alt="Sample" />
                        <CardBody className="p-0 d-flex flex-wrap align-items-stretch">
                          <CardTitle className="py-2 px-2 align-self-start w-100" tag="h5">
                            {images?.name}
                          </CardTitle>
                          <CardSubtitle className="mb-0 mt-2 py-2 px-2 text-muted align-self-end w-100" tag="h6">
                            {/* {images?.albumAddDate} */}
                            <div className="">{moment(images?.albumAddDate).format('LL')}</div>
                            <div className="card-icons d-flex gap-2">
                              <Button onClick={() => navigate(`/all-photos/${images.imageId}`)}>
                                <FontAwesomeIcon icon="pencil-alt" />
                              </Button>
                              {/* <Button onClick={() => deleteHandle(images.imageId)}> */}
                              <Button onClick={() => navigate(`/all-photos/${images.imageId}/delete`)}>
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </CardSubtitle>
                        </CardBody>
                      </Nav>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          ) : (
            ''
          )}
        </CardBody>
      </Card>

      {/* <dl className="jh-entity-details">
        <dt>
          <span id="id">
            <Translate contentKey="global.field.id">ID</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.regionId}</dd>
        <dt>
          <span id="latitude">
            <Translate contentKey="myApp.regions.latitude">Latitude</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.latitude}</dd>
        <dt>
          <span id="regionId">
            <Translate contentKey="myApp.regions.regionId">Region Id</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.regionId}</dd>
        <dt>
          <span id="defaultimageUrl">
            <Translate contentKey="myApp.regions.defaultimageUrl">Defaultimage Url</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.defaultimageUrl}</dd>
        <dt>
          <span id="imagesCount">
            <Translate contentKey="myApp.regions.imagesCount">Images Count</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.imagesCount}</dd>
        <dt>
          <span id="longitude">
            <Translate contentKey="myApp.regions.longitude">Longitude</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.longitude}</dd>
        <dt>
          <span id="minZoom">
            <Translate contentKey="myApp.regions.minZoom">Min Zoom</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.minZoom}</dd>
        <dt>
          <span id="name">
            <Translate contentKey="myApp.regions.name">Name</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.name}</dd>
        <dt>
          <span id="description">
            <Translate contentKey="myApp.regions.description">Description</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.description}</dd>
        <dt>
          <span id="productIdentifer">
            <Translate contentKey="myApp.regions.productIdentifer">Product Identifer</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.productIdentifer}</dd>
        <dt>
          <span id="isHidden">
            <Translate contentKey="myApp.regions.isHidden">Is Hidden</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.isHidden}</dd>
        <dt>
          <span id="isPaid">
            <Translate contentKey="myApp.regions.isPaid">Is Paid</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.isPaid ? 'true' : 'false'}</dd>
        <dt>
          <span id="maxZoom">
            <Translate contentKey="myApp.regions.maxZoom">Max Zoom</Translate>
          </span>
        </dt>
        <dd>{regionsEntity.maxZoom}</dd>
      </dl>
      <Button tag={Link} to="/map-regions" replace color="info" data-cy="entityDetailsBackButton">
        <FontAwesomeIcon icon="arrow-left" />{' '}
        <span className="d-none d-md-inline">
          <Translate contentKey="entity.action.back">Back</Translate>
        </span>
      </Button>
      &nbsp;
      <Button tag={Link} to={`/map-regions/map-detail/${regionsEntity.regionId}/edit`} replace color="primary">
        <FontAwesomeIcon icon="pencil-alt" />{' '}
        <span className="d-none d-md-inline">
          <Translate contentKey="entity.action.edit">Edit</Translate>
        </span>
      </Button> */}
    </>
  );
};

export default RegionsDetail;
