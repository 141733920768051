import { IImages } from 'app/shared/model/images.model';

export interface IAlbums {
  id?: number;
  albumId?: number | null;
  defaultImageUrl?: string | null;
  description?: string | null;
  imagesCount?: number | null;
  name?: string | null;
  isHidden?: boolean | null;
  productIdentifier?: string | null;
  ispaid?: boolean | null;
  images?: IImages | null;
}

export const defaultValue: Readonly<IAlbums> = {
  isHidden: false,
  ispaid: false,
};
