import { IPosts } from 'app/shared/model/posts.model';

export interface IComments {
  id?: number;
  commentId?: number | null;
  content?: string | null;
  createdDate?: number | null;
  fkpostId?: number | null;
  fkuserId?: number | null;
  flagsCount?: number | null;
  isFlagged?: number | null;
  fkflaggerId?: number | null;
  isArchived?: number | null;
  posts?: IPosts | null;
}

export const defaultValue: Readonly<IComments> = {};
