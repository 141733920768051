import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Row, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUserDataShares } from 'app/shared/model/user-data-shares.model';
import { getEntities as getShareEntities, getappdownloadsData } from './user-data-shares.reducer';
import { getEntities as getDownloadEntities } from '../user-data-downloads/user-data-downloads.reducer';

export const UserDataShares = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const userDataSharesList = useAppSelector(state => state.userDataShares.entities);
  const userDataDownloads = useAppSelector(state => state.userDataDownloads.entities);
  const shareLoading = useAppSelector(state => state.userDataShares.loading);
  const downloadLoading = useAppSelector(state => state.userDataShares.loading);

  const [appDownloadsLoading, setAppDownloadsLoading] = useState(true);
  const [appDownloadsData, setAppDownloadsData] = useState<any>({});

  useEffect(() => {
    (async () => {
      await dispatch(getShareEntities({}));
      await dispatch(getDownloadEntities({}));
      let tempAppdownloadsData = await dispatch(getappdownloadsData({}));
      setAppDownloadsData(tempAppdownloadsData.payload);
      setAppDownloadsLoading(false);
    })();
  }, []);

  return (
    <>
      {shareLoading || downloadLoading || appDownloadsLoading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 id="regions-heading" data-cy="RegionsHeading" className="page-title">
          User Data
        </h3>
        <div>
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h5">Add Users</CardTitle>
            </CardBody>
            <CardBody>
              <Row gutter={[24, 12]}>
                <Col md="4">
                  <div>
                    <h3>Total as of today</h3>
                    {appDownloadsData?.todayCounts}
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <h3>This Month</h3>
                    {appDownloadsData?.thisMonthCounts}
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <h3>This Month</h3>
                    {appDownloadsData?.lastMonthCounts}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row gutter={[24, 12]} className="mb-3">
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">No. of Maps Downloaded as of Today</CardTitle>
                </CardBody>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <div>{userDataDownloads?.todayCounts}</div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">No. of People Who Downloaded all Map</CardTitle>
                </CardBody>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <div>0</div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h5">No. of shares</CardTitle>
            </CardBody>
            <CardBody>
              <Row>
                <Col md="3">
                  <div>
                    <h3>Total As of Today</h3>
                    {userDataSharesList?.community}
                  </div>
                </Col>
                <Col md="3">
                  <div>
                    <h3>On Facebook</h3>0
                  </div>
                </Col>
                <Col md="3">
                  <div>
                    <h3>On Instagram</h3>0
                  </div>
                </Col>
                <Col md="3">
                  <div>
                    <h3>In App</h3>
                    {userDataSharesList?.todayCounts}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );

  // return (
  //   <div>
  //     <h2 id="user-data-shares-heading" data-cy="UserDataSharesHeading">
  //       <Translate contentKey="myApp.userDataShares.home.title">User Data Shares</Translate>
  //       <div className="d-flex justify-content-end">
  //         <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
  //           <FontAwesomeIcon icon="sync" spin={loading} />{' '}
  //           <Translate contentKey="myApp.userDataShares.home.refreshListLabel">Refresh List</Translate>
  //         </Button>
  //         <Link to="/user-data-shares/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
  //           <FontAwesomeIcon icon="plus" />
  //           &nbsp;
  //           <Translate contentKey="myApp.userDataShares.home.createLabel">Create new User Data Shares</Translate>
  //         </Link>
  //       </div>
  //     </h2>
  //     <div className="table-responsive">
  //       {userDataSharesList && userDataSharesList.length > 0 ? (
  //         <Table responsive>
  //           <thead>
  //             <tr>
  //               <th>
  //                 <Translate contentKey="myApp.userDataShares.id">ID</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.userDataShares.userDataShareId">User Data Share Id</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.userDataShares.createdDate">Created Date</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.userDataShares.modifiedDate">Modified Date</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.userDataShares.type">Type</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.userDataShares.userId">User Id</Translate>
  //               </th>
  //               <th />
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {userDataSharesList.map((userDataShares, i) => (
  //               <tr key={`entity-${i}`} data-cy="entityTable">
  //                 <td>
  //                   <Button tag={Link} to={`/user-data-shares/${userDataShares.id}`} color="link" size="sm">
  //                     {userDataShares.id}
  //                   </Button>
  //                 </td>
  //                 <td>{userDataShares.userDataShareId}</td>
  //                 <td>{userDataShares.createdDate}</td>
  //                 <td>{userDataShares.modifiedDate}</td>
  //                 <td>{userDataShares.type}</td>
  //                 <td>{userDataShares.userId}</td>
  //                 <td className="text-end">
  //                   <div className="btn-group flex-btn-group-container">
  //                     <Button tag={Link} to={`/user-data-shares/${userDataShares.id}`} color="info" size="sm" data-cy="entityDetailsButton">
  //                       <FontAwesomeIcon icon="eye" />{' '}
  //                       <span className="d-none d-md-inline">
  //                         <Translate contentKey="entity.action.view">View</Translate>
  //                       </span>
  //                     </Button>
  //                     <Button
  //                       tag={Link}
  //                       to={`/user-data-shares/${userDataShares.id}/edit`}
  //                       color="primary"
  //                       size="sm"
  //                       data-cy="entityEditButton"
  //                     >
  //                       <FontAwesomeIcon icon="pencil-alt" />{' '}
  //                       <span className="d-none d-md-inline">
  //                         <Translate contentKey="entity.action.edit">Edit</Translate>
  //                       </span>
  //                     </Button>
  //                     <Button
  //                       tag={Link}
  //                       to={`/user-data-shares/${userDataShares.id}/delete`}
  //                       color="danger"
  //                       size="sm"
  //                       data-cy="entityDeleteButton"
  //                     >
  //                       <FontAwesomeIcon icon="trash" />{' '}
  //                       <span className="d-none d-md-inline">
  //                         <Translate contentKey="entity.action.delete">Delete</Translate>
  //                       </span>
  //                     </Button>
  //                   </div>
  //                 </td>
  //               </tr>
  //             ))}
  //           </tbody>
  //         </Table>
  //       ) : (
  //         !loading && (
  //           <div className="alert alert-warning">
  //             <Translate contentKey="myApp.userDataShares.home.notFound">No User Data Shares found</Translate>
  //           </div>
  //         )
  //       )}
  //     </div>
  //   </div>
  // );
};

export default UserDataShares;
