import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { byteSize, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, getSearchEntities } from './favourite.reducer';
import { downloadImage } from './favourite.reducer';
import Search from 'app/override/components/common/search';

import moment from 'moment';

export const Favourites = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [favouriteList, setFavouriteList] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedList = favouriteList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Function to go to the next page
  const nextPage = () => {
    if (currentPage < Math.ceil(favouriteList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    (async () => {
      const userData = await dispatch(getEntities({}));
      console.log(userData, 'userDatauserData');
      let data: any = userData.payload;
      setFavouriteList(data);
      setLoading(false);
    })();
  }, []);

  const searchHandle = async (value: string) => {
    setLoading(true);
    let userData;
    if (value) {
      userData = await dispatch(getSearchEntities(value));
    } else {
      userData = await dispatch(getEntities({}));
    }
    let data: any = userData.payload;

    setFavouriteList(data);
    setLoading(false);
  };

  const handleDownloadClick = url => {
    dispatch(downloadImage(url)).then(action => {
      if (downloadImage.fulfilled.match(action)) {
        const imageBlob = action.payload;
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        const imageUrl = URL.createObjectURL(imageBlob);
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = fileName;
        link.click();
      } else if (downloadImage.rejected.match(action)) {
        console.error('Error downloading image:', action.error.message);
      }
    });
  };

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = favouriteList.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {loading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title">Liked Posts Overview</h3>
        <div>
          <Search searchHandle={searchHandle} />
        </div>
        <div className="table-responsive mb-3">
          <Table className="table table-borderless table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Photo</th>
                <th>Post By</th>
                <th>#OF Likes</th>
                <th>Region</th>
                <th>Download</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>
                    <img className="img-thumbnail" src={item.communityImage.url} alt="Post" />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.likesCount}</td>
                  <td>{item.regionName}</td>
                  <td>
                    <button className="btn btn-sm theme-btn" onClick={() => handleDownloadClick(item.communityImage.url)}>
                      Download
                    </button>
                  </td>
                  <td>{moment(item.communityImage.createdDate?.modifiedDate).format('L')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={prevPage}>
              Previous
            </button>
          </li>
          <li className={`page-item ${currentPage === Math.ceil(favouriteList.length / itemsPerPage) ? 'disabled' : ''}`}>
            <button className="page-link" onClick={nextPage}>
              Next
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Favourites;
