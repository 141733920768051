import { IPurchaseInfo } from 'app/shared/model/purchase-info.model';

export interface IProductIdentifiers {
  id?: number;
  productIdentifierId?: number | null;
  createdDate?: number | null;
  identifier?: string | null;
  storeType?: string | null;
  legacyId?: number | null;
  productType?: string | null;
  purchaseInfo?: IPurchaseInfo | null;
}

export const defaultValue: Readonly<IProductIdentifiers> = {};
