import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { useDispatch } from 'react-redux';
import { getIdentifiersAlbumList } from './albums.reducer';

const Products = () => {
  const dispatch = useAppDispatch();
  const [productsList, setProductsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let data: any = await dispatch(getIdentifiersAlbumList({}));
      console.log(data?.payload?.data, 'datadata');

      setProductsList(data?.payload?.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title">Products</h3>
        {productsList &&
          productsList.length > 0 &&
          productsList.map((item, index) => {
            return (
              <div className="product-list" key={index}>
                {item.identifier}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Products;
