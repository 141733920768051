import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPosts, defaultValue } from 'app/shared/model/posts.model';

const initialState: EntityState<IPosts> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'anseladams/api/posts';

// https://backendtest.findyouransel.app//anseladams/api/posts/unarchivecomments/355/13

// Actions

export const getEntities = createAsyncThunk('posts/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  cleanEntity({});
  const requestUrl = `${apiUrl}/flagged`;
  return axios.get<IPosts[]>(requestUrl);
});

// export const getSearchEntities = createAsyncThunk('posts/fetch_entity_list', async (value: string) => {
//   const requestUrl = `${apiUrl}/flagged/${value}/search`;
//   // return axios.get<IImages[]>(requestUrl);
//   let images = await axios.get<IPosts[]>(requestUrl);
//   console.log(images, images);
//   return images.data;
// });

export const getSearchEntities = createAsyncThunk('posts/fetch_entity_list', async (value: string) => {
  cleanEntity({});
  const requestUrl = `${apiUrl}/flagged/${value}/search`;
  return axios.get<IPosts[]>(requestUrl);
});

export const getArchivePostsEntities = createAsyncThunk('posts/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  cleanEntity({});
  const requestUrl = `${apiUrl}/archive`;
  return axios.get<IPosts[]>(requestUrl);
});

export const getArchiveCommentsEntities = createAsyncThunk('posts/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  cleanEntity({});
  const requestUrl = `${apiUrl}/archivedComments`;
  return axios.get<IPosts[]>(requestUrl);
});

export const getFlaggedCommentEntities = createAsyncThunk('posts/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  cleanEntity({});
  const requestUrl = `${apiUrl}/flaggedComments`;
  return axios.get<IPosts[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'posts/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPosts>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'posts/create_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.post<IPosts>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.put<IPosts>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const unArchiveEntity = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/${entity.postId}/unarchive`, cleanEntity(entity));
    thunkAPI.dispatch(getArchivePostsEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const unarchivecommentsEntity = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/unarchivecomments/${entity?.postId}/${entity?.commentId}`, cleanEntity(entity));
    thunkAPI.dispatch(getArchiveCommentsEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const unFlagEntity = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/unflag/${entity.postId}/${entity.userId}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const unFlagComments = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/unflagcomments/${entity.postId}/${entity.commentId}`, cleanEntity(entity));
    thunkAPI.dispatch(getFlaggedCommentEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const archivecomments = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/archivecomments/${entity.postId}/${entity.commentId}`, cleanEntity(entity));
    thunkAPI.dispatch(getFlaggedCommentEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const archiveEntity = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/${entity.postId}/archive`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'posts/partial_update_entity',
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'posts/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IPosts>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PostsSlice = createEntitySlice({
  name: 'posts',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(
        isFulfilled(getEntities, getFlaggedCommentEntities, getArchiveCommentsEntities, getArchivePostsEntities),
        (state, action) => {
          const { data } = action.payload;

          return {
            ...state,
            loading: false,
            entities: data,
          };
        }
      )
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(
        isPending(getEntities, getFlaggedCommentEntities, getArchiveCommentsEntities, getArchivePostsEntities, getEntity),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          unFlagEntity,
          unArchiveEntity,
          unarchivecommentsEntity,
          unFlagComments,
          archiveEntity,
          archivecomments,
          deleteEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = PostsSlice.actions;

// Reducer
export default PostsSlice.reducer;
