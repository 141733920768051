import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './comments.reducer';

export const CommentsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const commentsEntity = useAppSelector(state => state.comments.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="commentsDetailsHeading">
          <Translate contentKey="myApp.comments.detail.title">Comments</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.id}</dd>
          <dt>
            <span id="commentId">
              <Translate contentKey="myApp.comments.commentId">Comment Id</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.commentId}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="myApp.comments.content">Content</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.content}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="myApp.comments.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.createdDate}</dd>
          <dt>
            <span id="fkpostId">
              <Translate contentKey="myApp.comments.fkpostId">Fkpost Id</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.fkpostId}</dd>
          <dt>
            <span id="fkuserId">
              <Translate contentKey="myApp.comments.fkuserId">Fkuser Id</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.fkuserId}</dd>
          <dt>
            <span id="flagsCount">
              <Translate contentKey="myApp.comments.flagsCount">Flags Count</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.flagsCount}</dd>
          <dt>
            <span id="isFlagged">
              <Translate contentKey="myApp.comments.isFlagged">Is Flagged</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.isFlagged}</dd>
          <dt>
            <span id="fkflaggerId">
              <Translate contentKey="myApp.comments.fkflaggerId">Fkflagger Id</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.fkflaggerId}</dd>
          <dt>
            <span id="isArchived">
              <Translate contentKey="myApp.comments.isArchived">Is Archived</Translate>
            </span>
          </dt>
          <dd>{commentsEntity.isArchived}</dd>
          <dt>
            <Translate contentKey="myApp.comments.posts">Posts</Translate>
          </dt>
          <dd>{commentsEntity.posts ? commentsEntity.posts.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/comments" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/comments/${commentsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CommentsDetail;
