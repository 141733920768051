import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { useAppDispatch } from 'app/config/store';
import { IUsers } from 'app/shared/model/users.model';
import { getunactivatedUsers, sendActivationReminderEmail } from './users.reducer';
import moment from 'moment';
import ScrollToTop from '../../../scrollTop';

const InactiveUsers = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleSendEmail = async (userId: number) => {
    try {
      await dispatch(sendActivationReminderEmail(userId));
    } catch (error) {
      console.error('Failed to send the email:', error);
      console.error('Response data:', error.response.data);
    }
  };

  useEffect(() => {
    (async () => {
      let userData = await dispatch(getunactivatedUsers({}));
      console.log(userData, 'userDatauserData');
      let data: any = userData.payload;
      setUsersList(data);
      setLoading(false);
    })();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = usersList.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < Math.ceil(usersList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <ScrollToTop />
      {loading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 className="page-title">Inactive Users</h3>
        <div className="table-responsive mb-3">
          <Table className="table-borderless table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems?.length > 0 &&
                currentItems?.map((item, index) => {
                  const nameParts = item.name.split(' ');
                  const firstName = nameParts[0];
                  const lastName = nameParts[1];

                  return (
                    <tr key={index}>
                      <td>{item.email}</td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{moment(item?.modifiedDate).format('L')}</td>
                      <td>
                        <Button className="theme-btn py-2 px-3 text-nowrap" onClick={() => handleSendEmail(item.userId)}>
                          Send Email
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="pagination">
        <button className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage}>
          Previous
        </button>
        <button className={`page-link ${currentPage === Math.ceil(usersList.length / itemsPerPage) ? 'disabled' : ''}`} onClick={nextPage}>
          Next
        </button>
      </div>
    </>
  );
};

export default InactiveUsers;
