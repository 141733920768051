import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Images from './images';
import Users from './users';
import Albums from './albums';
import Experiences from './experiences';
import DeletedUserOtp from './deleted-user-otp';
import Locations from './locations';
import Comments from './comments';
import PurchaseInfo from './purchase-info';
import UserDataShares from './user-data-shares';
import FrontImage from './front-image';
import UserDataDownloads from './user-data-downloads';
import CommunityImages from './community-images';
import FlaggedPosts from './flagged-posts';
import Regions from './regions';
import FlaggedReasons from './flagged-reasons';
import BlockedUsers from './blocked-users';
import ProductIdentifiers from './product-identifiers';
import Posts from './posts';
import UserDevices from './user-devices';
import UserLikePost from './user-like-post';
import Notifications from './notifications';
import InactiveUsers from './users/user-unactivated';
// import InactiveUsers from './users-unactivated';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        {/* <Route path="images/*" element={<Images />} /> */}
        <Route path="all-photos/*" element={<Images />} />
        <Route path="userconsent/*" element={<Users />} />
        <Route path="unactivated-user/*" element={<InactiveUsers />} />
        <Route path="albums/*" element={<Albums />} />
        <Route path="experiences/*" element={<Experiences />} />
        <Route path="deleted-user-otp/*" element={<DeletedUserOtp />} />
        <Route path="locations/*" element={<Locations />} />
        <Route path="comments/*" element={<Comments />} />
        <Route path="purchase-info/*" element={<PurchaseInfo />} />
        <Route path="user-data/*" element={<UserDataShares />} />
        {/* <Route path="user-data-shares/*" element={<UserDataShares />} /> */}
        <Route path="front-image/*" element={<FrontImage />} />
        <Route path="user-data-downloads/*" element={<UserDataDownloads />} />
        <Route path="community-images/*" element={<CommunityImages />} />
        <Route path="flagged-posts/*" element={<FlaggedPosts />} />
        {/* <Route path="regions/*" element={<Regions />} /> */}
        <Route path="map-regions/*" element={<Regions />} />
        <Route path="flagged-reasons/*" element={<FlaggedReasons />} />
        <Route path="blocked-users/*" element={<BlockedUsers />} />
        <Route path="product-identifiers/*" element={<ProductIdentifiers />} />
        {/* <Route path="posts/*" element={<Posts />} />report */}
        <Route path="report/*" element={<Posts />} />
        <Route path="user-devices/*" element={<UserDevices />} />
        <Route path="user-like-post/*" element={<UserLikePost />} />
        <Route path="notifications/*" element={<Notifications />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
