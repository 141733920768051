import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, FormGroup, Label, Input, CardBody, Card } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IAlbums } from 'app/shared/model/albums.model';
import { getEntities as getAlbums } from 'app/entities/albums/albums.reducer';
import { ILocations } from 'app/shared/model/locations.model';
import { getEntities as getLocations } from 'app/entities/locations/locations.reducer';
import { IRegions } from 'app/shared/model/regions.model';
import { getEntities as getRegions } from 'app/entities/regions/regions.reducer';
import { IImages } from 'app/shared/model/images.model';
import { getEntity, updateEntity, createEntity, reset } from './images.reducer';

export const ImagesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const albums = useAppSelector(state => state.albums.entities);
  const locations = useAppSelector(state => state.locations.entities);
  const regions = useAppSelector(state => state.regions.entities);
  const imagesEntity = useAppSelector(state => state.images.entity);
  const loading = useAppSelector(state => state.images.loading);
  const updating = useAppSelector(state => state.images.updating);
  const updateSuccess = useAppSelector(state => state.images.updateSuccess);

  const handleClose = () => {
    navigate('/all-photos');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    // dispatch(getAlbums({}));
    // dispatch(getLocations({}));
    // dispatch(getRegions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (isNew) {
      let queryParam = '';
      Object.keys(values).forEach(key => {
        console.log(key, values[key]);
        if (values[key] && key != 'file') {
          queryParam += `${key}=${values[key]}&`;
        }
      });
      let newQueryParam = queryParam.substring(0, queryParam.length - 1);
      let formData = new FormData();
      formData.append('file[]', values.file[0]);

      let newData = { form: formData, query: newQueryParam };

      dispatch(createEntity(newData));
    } else {
      const entity = {
        ...values,
      };
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...imagesEntity,
          regions: imagesEntity?.regions?.id,
        };
  loading;
  return (
    <>
      {loading || updating ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h3 id="myApp.images.home.createOrEditLabel" data-cy="ImagesCreateUpdateHeading" className="page-title">
              <Translate contentKey="myApp.images.home.createOrEditLabel">Edit photos details</Translate>
            </h3>
          </Col>
        </Row>
        <Row className="image-edit-form">
          <Col md="12">
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="bg-white py-4 px-2 row">
              {isNew ? (
                <ValidatedField
                  id="images-file"
                  name="file"
                  data-cy="file"
                  type="file"
                  label={'Choose File'}
                  validate={{ required: true }}
                  className="col-12 col-md-6 col-lg-4 position-relative"
                  onChange={event => {
                    console.log(event);
                  }}
                />
              ) : (
                <Col md={12}>
                  <img className="img-thumbnail mb-4 p-0" src={imagesEntity.url} />
                </Col>
              )}
              {!isNew ? (
                <ValidatedField
                  name="imageId"
                  required
                  readOnly
                  id="images-id"
                  className="col-12 col-md-6 col-lg-4"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('myApp.images.name')}
                id="images-name"
                name="name"
                data-cy="name"
                type="text"
                className="col-12 col-md-6 col-lg-4"
                validate={{ required: true }}
              />
              <ValidatedField
                label={translate('myApp.images.year')}
                id="images-year"
                name="year"
                data-cy="year"
                type="text"
                className="col-12 col-md-6 col-lg-4"
              />
              {/* <ValidatedField
                label={translate('myApp.images.cautionMessage')}
                id="images-cautionMessage"
                name="cautionMessage"
                data-cy="cautionMessage"
                type="textarea"
              />
               <ValidatedField label={translate('myApp.images.imageId')} id="images-imageId" name="imageId" data-cy="imageId" type="text" /> 
              <ValidatedField
                label={translate('myApp.images.createdDate')}
                id="images-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="text"
              /> */}

              <ValidatedField
                className="col-12 col-md-6 col-lg-4"
                label={translate('myApp.images.latitude')}
                id="images-latitude"
                name="latitude"
                data-cy="latitude"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.images.longitude')}
                id="images-longitude"
                name="longitude"
                data-cy="longitude"
                type="text"
                className="col-12 col-md-6 col-lg-4"
              />
              <ValidatedField
                label={translate('myApp.images.tags')}
                id="images-tags"
                name="tags"
                data-cy="tags"
                type="text"
                className="col-12 col-md-6 col-lg-4"
              />

              <ValidatedField
                label={translate('myApp.images.description')}
                id="images-description"
                name="description"
                data-cy="description"
                type="textarea"
                className="col-12 col-md-6 col-lg-4"
                validate={{ required: true }}
              />

              <ValidatedField
                label={translate('myApp.images.cautionMessage')}
                id="images-cautionMessage"
                name="cautionMessage"
                data-cy="cautionMessage"
                type="textarea"
                className="col-12 col-md-6 col-lg-4"
              />
              <ValidatedField
                label={translate('myApp.images.info')}
                id="images-info"
                name="info"
                data-cy="info"
                type="textarea"
                className="col-12 col-md-6 col-lg-4"
              />

              {/* <ValidatedField
                    label={translate('myApp.images.fkAlbumAddDate')}
                    id="images-fkAlbumAddDate"
                    name="fkAlbumAddDate"
                    data-cy="fkAlbumAddDate"
                    type="text"
                  />
                  <ValidatedField
                    label={translate('myApp.images.modifiedDate')}
                    id="images-modifiedDate"
                    name="modifiedDate"
                    data-cy="modifiedDate"
                    type="text"
                  />
                  <ValidatedField
                    label={translate('myApp.images.fkRegionId')}
                    id="images-fkRegionId"
                    name="fkRegionId"
                    data-cy="fkRegionId"
                    type="text"
                  />

                  <ValidatedField
                    label={translate('myApp.images.fkAlbumId')}
                    id="images-fkAlbumId"
                    name="fkAlbumId"
                    data-cy="fkAlbumId"
                    type="text"
                  />
                  <ValidatedField
                    label={translate('myApp.images.watermarkedUrl')}
                    id="images-watermarkedUrl"
                    name="watermarkedUrl"
                    data-cy="watermarkedUrl"
                    type="textarea"
                  />
                  <ValidatedField
                    label={translate('myApp.images.overallAccessibility')}
                    id="images-overallAccessibility"
                    name="overallAccessibility"
                    data-cy="overallAccessibility"
                    type="text"
                  />
                  <ValidatedField label={translate('myApp.images.tags')} id="images-tags" name="tags" data-cy="tags" type="text" />
                  <ValidatedField label={translate('myApp.images.s3Key')} id="images-s3Key" name="s3Key" data-cy="s3Key" type="textarea" />
                  <ValidatedField label={translate('myApp.images.url')} id="images-url" name="url" data-cy="url" type="textarea" />
                  <ValidatedField
                    label={translate('myApp.images.fileName')}
                    id="images-fileName"
                    name="fileName"
                    data-cy="fileName"
                    type="text"
                  />
                  <ValidatedField
                    label={translate('myApp.images.fkLocationId')}
                    id="images-fkLocationId"
                    name="fkLocationId"
                    data-cy="fkLocationId"
                    type="text"
                  />
                  <ValidatedField
                    label={translate('myApp.images.fkRegionAddDate')}
                    id="images-fkRegionAddDate"
                    name="fkRegionAddDate"
                    data-cy="fkRegionAddDate"
                    type="text"
                  />
                  <ValidatedField label={translate('myApp.images.info')} id="images-info" name="info" data-cy="info" type="textarea" />
                  <ValidatedField label={translate('myApp.images.year')} id="images-year" name="year" data-cy="year" type="text" />
                  <ValidatedField id="images-regions" name="regions" data-cy="regions" label={translate('myApp.images.regions')} type="select">
                    <option value="" key="0" />
                    {regions
                      ? regions.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                      : null}
                  </ValidatedField> */}
              <Col xs="12"></Col>
              <Col xs="auto mt-3">
                <Button
                  className="secondary-theme-btn"
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to="/all-photos"
                  replace
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              </Col>

              <Col xs="auto mt-3">
                <Button className="theme-btn" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ImagesUpdate;
