import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUsers, defaultValue } from 'app/shared/model/users.model';

const initialState: EntityState<IUsers> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'anseladams/api/users/';

// Actions

export const sendActivationReminderEmail = createAsyncThunk('users/sendActivationReminderEmail', async (userId: number, thunkAPI) => {
  console.log(userId);

  try {
    const response = await axios.get(`/anseladams/api/users/sendActivationReminderEmail/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const getunactivatedUsers = createAsyncThunk('users/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  // const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrl}/unactivated`;
  let unactivatedUsers = await axios.get<IUsers[]>(requestUrl);
  console.log(unactivatedUsers.data, 'datadata');
  return unactivatedUsers.data;
});
export const getEntities = createAsyncThunk('users/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  // const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrl}`;
  return axios.get<IUsers[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'users/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUsers>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'users/create_entity',
  async (entity: IUsers, thunkAPI) => {
    const result = await axios.post<IUsers>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'users/update_entity',
  async (entity: IUsers, thunkAPI) => {
    const result = await axios.put<IUsers>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserEntity = createAsyncThunk(
  'users/update_entity',
  async (id: Number, thunkAPI) => {
    const result = await axios.put<IUsers>(`${apiUrl}/setUserAccountStatus/${id}?accountStatus=false&adminAction=true`);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'users/partial_update_entity',
  async (entity: IUsers, thunkAPI) => {
    const result = await axios.patch<IUsers>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'users/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IUsers>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const UsersSlice = createEntitySlice({
  name: 'users',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, updateUserEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });

    builder.addCase(sendActivationReminderEmail.pending, state => {
      state.loading = true;
      state.errorMessage = null; // Reset the error when making a request.
    });

    builder.addCase(sendActivationReminderEmail.fulfilled, (state, action) => {
      state.loading = false;
      // Handle success here, e.g., update some state property
      // For example, you can set a flag like `emailSent` to true.
      // state.emailSent = true;
    });

    builder.addCase(sendActivationReminderEmail.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.error.message; // Store the error message in state.
    });
  },
});

export const { reset } = UsersSlice.actions;

// Reducer
export default UsersSlice.reducer;
