import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Nav, Row, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Search from 'app/override/components/common/search';

import { IAlbums } from 'app/shared/model/albums.model';
import { getEntities, getSearchEntities } from './albums.reducer';

export const Albums = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const albumsList = useAppSelector(state => state.albums.entities);
  const loading = useAppSelector(state => state.albums.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  const searchHandle = (value: string) => {
    if (value) {
      dispatch(getSearchEntities(value));
    } else {
      dispatch(getEntities({}));
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 id="regions-heading" data-cy="RegionsHeading" className="page-title">
          <Translate contentKey="myApp.albums.home.title">Map Regions Overview</Translate>
          {/* <div className="d-flex justify-content-end">
        <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="myApp.regions.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to="/map-regions/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="myApp.regions.home.createLabel">Create new Regions</Translate>
        </Link>
      </div> */}
        </h3>

        <div>
          <Search searchHandle={searchHandle} />
        </div>
        <div>
          {albumsList && albumsList.length > 0 ? (
            <Row className="g-4">
              {albumsList.map((item, i) => (
                <Col className="mb-2" xs="12" md="6" lg="4" xl="3" key={i}>
                  <Card className="h-100 p-0 shadow-sm rounded-0 border-0">
                    <Nav navbar className="h-100">
                      <img className="card-img-style" alt="Sample" src={item?.defaultImageUrl} />
                      <CardBody className="p-0 d-flex flex-wrap align-items-stretch">
                        <div className="d-flex justify-content-between align-items-center px-2 py-2 mb-2 border-bottom align-self-start w-100"></div>
                        <CardTitle className="px-2 align-self-start w-100" tag="h5">
                          {item?.name}
                        </CardTitle>
                        <CardSubtitle className="mb-0 text-muted px-2 py-2 align-self-end w-100 gap-2" tag="h6">
                          <div className="">{item?.imagesCount} photos</div>
                          <div className="card-icons d-flex gap-2">
                            {/* <FontAwesomeIcon icon="pencil-alt" />
                        <FontAwesomeIcon icon="trash" /> */}
                            <Button onClick={() => navigate(`${item.albumId}`)}>
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                            {/* <Button onClick={() => deleteHandle(images.imageId)}> */}
                            <Button onClick={() => navigate(`${item.albumId}/delete`)}>
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          </div>
                        </CardSubtitle>
                      </CardBody>
                    </Nav>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="myApp.regions.home.notFound">No Regions found</Translate>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );

  // return (
  //   <div>
  //     <h2 id="albums-heading" data-cy="AlbumsHeading">
  //       <Translate contentKey="myApp.albums.home.title">Albums</Translate>
  //       <div className="d-flex justify-content-end">
  //         <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
  //           <FontAwesomeIcon icon="sync" spin={loading} />{' '}
  //           <Translate contentKey="myApp.albums.home.refreshListLabel">Refresh List</Translate>
  //         </Button>
  //         <Link to="/albums/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
  //           <FontAwesomeIcon icon="plus" />
  //           &nbsp;
  //           <Translate contentKey="myApp.albums.home.createLabel">Create new Albums</Translate>
  //         </Link>
  //       </div>
  //     </h2>
  //     <div className="table-responsive">
  //       {albumsList && albumsList.length > 0 ? (
  //         <Table responsive>
  //           <thead>
  //             <tr>
  //               <th>
  //                 <Translate contentKey="myApp.albums.id">ID</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.albumId">Album Id</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.defaultImageUrl">Default Image Url</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.description">Description</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.imagesCount">Images Count</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.name">Name</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.isHidden">Is Hidden</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.productIdentifier">Product Identifier</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.ispaid">Ispaid</Translate>
  //               </th>
  //               <th>
  //                 <Translate contentKey="myApp.albums.images">Images</Translate>
  //               </th>
  //               <th />
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {albumsList.map((albums, i) => (
  //               <tr key={`entity-${i}`} data-cy="entityTable">
  //                 <td>
  //                   <Button tag={Link} to={`/albums/${albums.id}`} color="link" size="sm">
  //                     {albums.id}
  //                   </Button>
  //                 </td>
  //                 <td>{albums.albumId}</td>
  //                 <td>{albums.defaultImageUrl}</td>
  //                 <td>{albums.description}</td>
  //                 <td>{albums.imagesCount}</td>
  //                 <td>{albums.name}</td>
  //                 <td>{albums.isHidden ? 'true' : 'false'}</td>
  //                 <td>{albums.productIdentifier}</td>
  //                 <td>{albums.ispaid ? 'true' : 'false'}</td>
  //                 <td>{albums.images ? <Link to={`/images/${albums.images.id}`}>{albums.images.id}</Link> : ''}</td>
  //                 <td className="text-end">
  //                   <div className="btn-group flex-btn-group-container">
  //                     <Button tag={Link} to={`/albums/${albums.id}`} color="info" size="sm" data-cy="entityDetailsButton">
  //                       <FontAwesomeIcon icon="eye" />{' '}
  //                       <span className="d-none d-md-inline">
  //                         <Translate contentKey="entity.action.view">View</Translate>
  //                       </span>
  //                     </Button>
  //                     <Button tag={Link} to={`/albums/${albums.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
  //                       <FontAwesomeIcon icon="pencil-alt" />{' '}
  //                       <span className="d-none d-md-inline">
  //                         <Translate contentKey="entity.action.edit">Edit</Translate>
  //                       </span>
  //                     </Button>
  //                     <Button tag={Link} to={`/albums/${albums.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
  //                       <FontAwesomeIcon icon="trash" />{' '}
  //                       <span className="d-none d-md-inline">
  //                         <Translate contentKey="entity.action.delete">Delete</Translate>
  //                       </span>
  //                     </Button>
  //                   </div>
  //                 </td>
  //               </tr>
  //             ))}
  //           </tbody>
  //         </Table>
  //       ) : (
  //         !loading && (
  //           <div className="alert alert-warning">
  //             <Translate contentKey="myApp.albums.home.notFound">No Albums found</Translate>
  //           </div>
  //         )
  //       )}
  //     </div>
  //   </div>
  // );
};

export default Albums;
