export interface IUserDataShares {
  id?: number;
  userDataShareId?: number | null;
  createdDate?: number | null;
  modifiedDate?: number | null;
  type?: number | null;
  userId?: number | null;
}

export const defaultValue: Readonly<IUserDataShares> = {};
