import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IImages } from 'app/shared/model/images.model';
// import { getEntities as getImages } from 'app/entities/images/images.reducer';
import { IAlbums } from 'app/shared/model/albums.model';
import { getEntity, updateEntity, createEntity, reset, getEntityImages } from './albums.reducer';

export const AlbumsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const images = useAppSelector(state => state.images.entities);
  const albumsEntityData = useAppSelector(state => state.albums.entity);
  console.log(albumsEntityData, 'albumsEntityData');

  const loading = useAppSelector(state => state.albums.loading);
  const updating = useAppSelector(state => state.albums.updating);
  const updateSuccess = useAppSelector(state => state.albums.updateSuccess);

  const [albumsEntity, setAlbumEntity] = useState(isNew ? undefined : albumsEntityData);
  console.log(albumsEntity, 'albumsEntityalbumsEntity');

  const [imageLoading, setImageLoading] = useState(isNew ? false : true);
  const [albumImages, setAlbumImages] = useState([]);
  const [imagesModal, setImagesModal] = useState(false);
  const imagesModalHandle = () => setImagesModal(!imagesModal);

  const handleClose = () => {
    navigate('/albums');
  };

  useEffect(() => {
    setAlbumEntity(isNew ? undefined : albumsEntityData);
  }, [albumsEntityData]);

  useEffect(() => {
    (async () => {
      if (isNew) {
        dispatch(reset());
      } else {
        dispatch(getEntity(id));
        const { payload } = await dispatch(getEntityImages(id));
        let data: any = payload;
        setAlbumImages(data);
        setImageLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...albumsEntity,
      ...values,
    };
    console.log(entity, 'entityentity');

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...albumsEntity,
          images: albumsEntity?.images?.id,
        };

  const imageHandler = url => {
    setAlbumEntity(prev => {
      return {
        ...prev,
        defaultImageUrl: url,
      };
    });
    imagesModalHandle();
  };

  return (
    <>
      {loading || imageLoading || updating ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h3 id="myApp.regions.home.createOrEditLabel" data-cy="RegionsCreateUpdateHeading" className="page-title">
              {isNew ? (
                <Translate contentKey="myApp.albums.home.createLabel">Create a Album</Translate>
              ) : (
                <Translate contentKey="myApp.albums.home.createOrEditLabel">Edit a Album</Translate>
              )}
            </h3>
          </Col>
        </Row>
        <Row className="image-edit-form">
          <Col md="12">
            <Card>
              <CardBody>
                <ValidatedForm className="row" defaultValues={defaultValues()} onSubmit={saveEntity}>
                  {/* {!isNew ? (
                    <ValidatedField
                      name="id"
                      required
                      readOnly
                      id="regions-id"
                      label={translate('global.field.id')}
                      validate={{ required: true }}
                    />
                  ) : null} */}
                  {isNew ? (
                    <ValidatedField
                      id="images-file"
                      name="file"
                      label={'Choose File'}
                      validate={{ required: true }}
                      data-cy="file"
                      type="file"
                      className="col-12 col-md-6 col-lg-4 position-relative"
                      onChange={event => {
                        console.log(event);
                      }}
                    />
                  ) : (
                    <Col md={12} className="mb-3">
                      <h5>Cover Image</h5>
                      <div className="img-edit">
                        <img className="p-0 w-100" src={albumsEntity?.defaultImageUrl} />
                        <Button className="theme-btn rounded-0 w-100" onClick={imagesModalHandle}>
                          <FontAwesomeIcon icon="camera" /> &nbsp; Change Image
                        </Button>
                      </div>

                      <Modal isOpen={imagesModal} toggle={imagesModalHandle}>
                        <ModalHeader toggle={imagesModalHandle}>Map Gallery</ModalHeader>
                        <ModalBody className="map-gallery ">
                          <Row className="g-3">
                            {albumImages &&
                              albumImages.length > 0 &&
                              albumImages.map((item, index) => {
                                return (
                                  <Col md="3">
                                    <img src={item?.url} className="select-img" onClick={() => imageHandler(item?.url)} />
                                  </Col>
                                );
                              })}
                          </Row>
                        </ModalBody>
                      </Modal>
                    </Col>
                  )}

                  <ValidatedField
                    label={translate('myApp.regions.name')}
                    id="regions-name"
                    name="name"
                    data-cy="name"
                    type="text"
                    className="col-12 col-md-6 col-lg-4"
                  />
                  <ValidatedField
                    label={translate('myApp.regions.description')}
                    id="regions-description"
                    name="description"
                    data-cy="description"
                    type="textarea"
                    className="col-12 col-md-6 col-lg-4"
                  />

                  <Col xs="12"></Col>
                  <Col xs="auto">
                    <Button
                      tag={Link}
                      id="cancel-save"
                      data-cy="entityCreateCancelButton"
                      to="/map-regions"
                      replace
                      className="secondary-theme-btn"
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating} className="theme-btn">
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </Col>
                </ValidatedForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
    // <div>
    //   <Row className="justify-content-center">
    //     <Col md="8">
    //       <h2 id="myApp.albums.home.createOrEditLabel" data-cy="AlbumsCreateUpdateHeading">
    //         <Translate contentKey="myApp.albums.home.createOrEditLabel">Create or edit a Albums</Translate>
    //       </h2>
    //     </Col>
    //   </Row>
    //   <Row className="justify-content-center">
    //     <Col md="8">
    //       {loading ? (
    //         <p>Loading...</p>
    //       ) : (
    //         <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
    //           {!isNew ? (
    //             <ValidatedField
    //               name="id"
    //               required
    //               readOnly
    //               id="albums-id"
    //               label={translate('global.field.id')}
    //               validate={{ required: true }}
    //             />
    //           ) : null}
    //           <ValidatedField label={translate('myApp.albums.albumId')} id="albums-albumId" name="albumId" data-cy="albumId" type="text" />
    //           <ValidatedField
    //             label={translate('myApp.albums.defaultImageUrl')}
    //             id="albums-defaultImageUrl"
    //             name="defaultImageUrl"
    //             data-cy="defaultImageUrl"
    //             type="text"
    //           />
    //           <ValidatedField
    //             label={translate('myApp.albums.description')}
    //             id="albums-description"
    //             name="description"
    //             data-cy="description"
    //             type="text"
    //           />
    //           <ValidatedField
    //             label={translate('myApp.albums.imagesCount')}
    //             id="albums-imagesCount"
    //             name="imagesCount"
    //             data-cy="imagesCount"
    //             type="text"
    //           />
    //           <ValidatedField label={translate('myApp.albums.name')} id="albums-name" name="name" data-cy="name" type="text" />
    //           <ValidatedField
    //             label={translate('myApp.albums.isHidden')}
    //             id="albums-isHidden"
    //             name="isHidden"
    //             data-cy="isHidden"
    //             check
    //             type="checkbox"
    //           />
    //           <ValidatedField
    //             label={translate('myApp.albums.productIdentifier')}
    //             id="albums-productIdentifier"
    //             name="productIdentifier"
    //             data-cy="productIdentifier"
    //             type="text"
    //           />
    //           <ValidatedField
    //             label={translate('myApp.albums.ispaid')}
    //             id="albums-ispaid"
    //             name="ispaid"
    //             data-cy="ispaid"
    //             check
    //             type="checkbox"
    //           />
    //           <ValidatedField id="albums-images" name="images" data-cy="images" label={translate('myApp.albums.images')} type="select">
    //             <option value="" key="0" />
    //             {images && images?.content && images?.content > 0 ? images?.content.map(otherEntity => (
    //               <option value={otherEntity.imageId} key={otherEntity.imageId}>
    //                 {otherEntity.imageId}
    //               </option>
    //             ))
    //               : null}
    //           </ValidatedField>
    //           <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/albums" replace color="info">
    //             <FontAwesomeIcon icon="arrow-left" />
    //             &nbsp;
    //             <span className="d-none d-md-inline">
    //               <Translate contentKey="entity.action.back">Back</Translate>
    //             </span>
    //           </Button>
    //           &nbsp;
    //           <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
    //             <FontAwesomeIcon icon="save" />
    //             &nbsp;
    //             <Translate contentKey="entity.action.save">Save</Translate>
    //           </Button>
    //         </ValidatedForm>
    //       )}
    //     </Col>
    //   </Row>
    // </div>
  );
};

export default AlbumsUpdate;
