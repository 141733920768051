import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './deleted-user-otp.reducer';

export const DeletedUserOtpDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const deletedUserOtpEntity = useAppSelector(state => state.deletedUserOtp.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="deletedUserOtpDetailsHeading">
          <Translate contentKey="myApp.deletedUserOtp.detail.title">DeletedUserOtp</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="myApp.deletedUserOtp.id">Id</Translate>
            </span>
          </dt>
          <dd>{deletedUserOtpEntity.id}</dd>
          <dt>
            <span id="otpCode">
              <Translate contentKey="myApp.deletedUserOtp.otpCode">Otp Code</Translate>
            </span>
          </dt>
          <dd>{deletedUserOtpEntity.otpCode}</dd>
          <dt>
            <span id="otpExpiry">
              <Translate contentKey="myApp.deletedUserOtp.otpExpiry">Otp Expiry</Translate>
            </span>
          </dt>
          <dd>{deletedUserOtpEntity.otpExpiry}</dd>
          <dt>
            <span id="otpStatus">
              <Translate contentKey="myApp.deletedUserOtp.otpStatus">Otp Status</Translate>
            </span>
          </dt>
          <dd>{deletedUserOtpEntity.otpStatus}</dd>
          <dt>
            <span id="fkuserId">
              <Translate contentKey="myApp.deletedUserOtp.fkuserId">Fkuser Id</Translate>
            </span>
          </dt>
          <dd>{deletedUserOtpEntity.fkuserId}</dd>
        </dl>
        <Button tag={Link} to="/deleted-user-otp" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/deleted-user-otp/${deletedUserOtpEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DeletedUserOtpDetail;
