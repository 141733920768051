import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Search = ({ searchHandle }) => {
  // https://backendtest.findyouransel.app//anseladams/regions/light/search
  const [searchValue, setSearchValue] = useState('');
  const changeHandle = (e: any) => {
    setSearchValue(e.target.value.trim());
  };

  return (
    <div className="mb-4 search-bar">
      <Input type="text" name="search" placeholder="Search..." value={searchValue} onChange={changeHandle} />
      <Button className="theme-btn text-nowrap" onClick={() => searchHandle(searchValue)}>
        <FontAwesomeIcon icon="search" />
      </Button>
      <Button
        className="text-dark"
        onClick={() => {
          setSearchValue('');
          searchHandle('');
        }}
      >
        <FontAwesomeIcon icon="circle-xmark" />
      </Button>
    </div>
  );
};

export default Search;
