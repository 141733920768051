import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import { Button, Col, Container, Row } from 'reactstrap';

const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleLogin = (username, password, rememberMe = false) => {
    dispatch(login(username, password, rememberMe));
  };

  const handleClose = () => {
    setShowModal(false);
    navigate('/');
  };

  if (isAuthenticated) {
    return <Navigate to="/map-regions" replace />;
  }

  const renderHeader = !isAuthenticated && (
    <header className="py-3">
      <Container>
        <Row className="login-style">
          <Col md={6} lg={7} className="p-0">
            <img src="/content/images/login-page-img.jpg" alt="Your Image" className="img-fluid" />
          </Col>
          <Col md={6} lg={5} className="d-flex align-items-center justify-content-center">
            <div className="px-3 py-5">
              <p className="fs-5 mb-0">After a few moments of simple instruction,</p>
              <p className="fs-5">
                my camera and I went off to explore<span className="theme-color">...</span>
              </p>
              <p className="mt-5 fw-bold mb-1">
                <span className="theme-color fw-bold">-</span> ANSEL ADAMS
              </p>
              {/* <Button color="primary" onClick={toggleModal}>
              Sign In
            </Button>{' '} */}
              <div className="small image-tag">
                <label>Canyon de Chelley</label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center text-center">
          <Col md={12} lg={10} xl={8}>
            <div className="d-flex flex-wrap gap-1 mt-3 mb-1 justify-content-center fw-bold fs-6">
              <label>CONTACT US</label>
              <label className="theme-color">|</label>
              <label>PRIVACY POLICY</label>
              <label className="theme-color">|</label>
              <label>TERMS & CONDITIONS </label>
              <label className="theme-color">|</label>
              <label>WWW.ANSELADAMS.COM</label>
            </div>
            <div className="small w-75 text-center mx-auto">
              <label>
                COPYRIGHT @ 2018 THE ANSEL ADAMS GALLERY. An Authorized concessioner of the National Park Service. ALL RIGHTS RESERVED.
              </label>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
  return (
    <div>
      {renderHeader}
      <LoginModal showModal={showModal} handleLogin={handleLogin} handleClose={handleClose} loginError={loginError} />
    </div>
  );
};

export default Login;
