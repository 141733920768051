import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FrontImage from './front-image';
import FrontImageDetail from './front-image-detail';
import FrontImageUpdate from './front-image-update';
import FrontImageDeleteDialog from './front-image-delete-dialog';

const FrontImageRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FrontImage />} />
    <Route path="new" element={<FrontImageUpdate />} />
    <Route path=":id">
      <Route index element={<FrontImageDetail />} />
      <Route path="edit" element={<FrontImageUpdate />} />
      <Route path="delete" element={<FrontImageDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FrontImageRoutes;
