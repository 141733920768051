import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICommunityImages } from 'app/shared/model/community-images.model';
import { getEntities as getCommunityImages } from 'app/entities/community-images/community-images.reducer';
import { IImages } from 'app/shared/model/images.model';
import { getEntities as getImages } from 'app/entities/images/images.reducer';
import { IRegions } from 'app/shared/model/regions.model';
import { getEntities as getRegions } from 'app/entities/regions/regions.reducer';
import { IPosts } from 'app/shared/model/posts.model';
import { getEntity, updateEntity, createEntity, reset } from './posts.reducer';

export const PostsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const communityImages = useAppSelector(state => state.communityImages.entities);
  const images = useAppSelector(state => state.images.entities);
  const regions = useAppSelector(state => state.regions.entities);
  const postsEntity = useAppSelector(state => state.posts.entity);
  const loading = useAppSelector(state => state.posts.loading);
  const updating = useAppSelector(state => state.posts.updating);
  const updateSuccess = useAppSelector(state => state.posts.updateSuccess);

  const handleClose = () => {
    navigate('/posts');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCommunityImages({}));
    dispatch(getImages({}));
    dispatch(getRegions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...postsEntity,
      ...values,
      images: images.find(it => it.id.toString() === values.images.toString()),
      regions: regions.find(it => it.id.toString() === values.regions.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...postsEntity,
          images: postsEntity?.images?.id,
          regions: postsEntity?.regions?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="myApp.posts.home.createOrEditLabel" data-cy="PostsCreateUpdateHeading">
            <Translate contentKey="myApp.posts.home.createOrEditLabel">Create or edit a Posts</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="posts-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('myApp.posts.postId')} id="posts-postId" name="postId" data-cy="postId" type="text" />
              <ValidatedField
                label={translate('myApp.posts.commentsCount')}
                id="posts-commentsCount"
                name="commentsCount"
                data-cy="commentsCount"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.content')}
                id="posts-content"
                name="content"
                data-cy="content"
                type="textarea"
              />
              <ValidatedField
                label={translate('myApp.posts.isArchived')}
                id="posts-isArchived"
                name="isArchived"
                data-cy="isArchived"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.createdDate')}
                id="posts-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.fkflaggerId')}
                id="posts-fkflaggerId"
                name="fkflaggerId"
                data-cy="fkflaggerId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.isFlagged')}
                id="posts-isFlagged"
                name="isFlagged"
                data-cy="isFlagged"
                type="text"
              />
              <ValidatedField label={translate('myApp.posts.isLiked')} id="posts-isLiked" name="isLiked" data-cy="isLiked" type="text" />
              <ValidatedField
                label={translate('myApp.posts.likesCount')}
                id="posts-likesCount"
                name="likesCount"
                data-cy="likesCount"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.location')}
                id="posts-location"
                name="location"
                data-cy="location"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.fkcommunityimageId')}
                id="posts-fkcommunityimageId"
                name="fkcommunityimageId"
                data-cy="fkcommunityimageId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.fkimageId')}
                id="posts-fkimageId"
                name="fkimageId"
                data-cy="fkimageId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.fkRegionId')}
                id="posts-fkRegionId"
                name="fkRegionId"
                data-cy="fkRegionId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.fkflaggedUserId')}
                id="posts-fkflaggedUserId"
                name="fkflaggedUserId"
                data-cy="fkflaggedUserId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.fkUserId')}
                id="posts-fkUserId"
                name="fkUserId"
                data-cy="fkUserId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.posts.flagsCount')}
                id="posts-flagsCount"
                name="flagsCount"
                data-cy="flagsCount"
                type="text"
              />
              <ValidatedField id="posts-images" name="images" data-cy="images" label={translate('myApp.posts.images')} type="select">
                <option value="" key="0" />
                {images
                  ? images.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="posts-regions" name="regions" data-cy="regions" label={translate('myApp.posts.regions')} type="select">
                <option value="" key="0" />
                {regions
                  ? regions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/posts" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PostsUpdate;
