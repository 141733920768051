import { IUsers } from 'app/shared/model/users.model';
import { IImages } from 'app/shared/model/images.model';

export interface IExperiences {
  id?: number;
  experienceIid?: number | null;
  accessibility?: string | null;
  content?: string | null;
  createdDate?: number | null;
  fkimageId?: number | null;
  fkuserId?: number | null;
  users?: IUsers | null;
  images?: IImages | null;
}

export const defaultValue: Readonly<IExperiences> = {};
