import { IFlaggedReasons } from 'app/shared/model/flagged-reasons.model';

export interface IFlaggedPosts {
  id?: number;
  description?: string | null;
  flagreasonId?: number | null;
  flaggedbyuserId?: number | null;
  postId?: number | null;
  flaggedReasons?: IFlaggedReasons | null;
}

export const defaultValue: Readonly<IFlaggedPosts> = {};
