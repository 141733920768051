import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IImages } from 'app/shared/model/images.model';
import { getEntities as getImages } from 'app/entities/images/images.reducer';
import { ILocations } from 'app/shared/model/locations.model';
import { getEntity, updateEntity, createEntity, reset } from './locations.reducer';

export const LocationsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const images = useAppSelector(state => state.images.entities);
  const locationsEntity = useAppSelector(state => state.locations.entity);
  const loading = useAppSelector(state => state.locations.loading);
  const updating = useAppSelector(state => state.locations.updating);
  const updateSuccess = useAppSelector(state => state.locations.updateSuccess);

  const handleClose = () => {
    navigate('/locations');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getImages({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...locationsEntity,
      ...values,
      images: images.find(it => it.id.toString() === values.images.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...locationsEntity,
          images: locationsEntity?.images?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="myApp.locations.home.createOrEditLabel" data-cy="LocationsCreateUpdateHeading">
            <Translate contentKey="myApp.locations.home.createOrEditLabel">Create or edit a Locations</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="locations-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('myApp.locations.locationId')}
                id="locations-locationId"
                name="locationId"
                data-cy="locationId"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.locations.defaultImageUrl')}
                id="locations-defaultImageUrl"
                name="defaultImageUrl"
                data-cy="defaultImageUrl"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.locations.latitude')}
                id="locations-latitude"
                name="latitude"
                data-cy="latitude"
                type="text"
              />
              <ValidatedField
                label={translate('myApp.locations.longitude')}
                id="locations-longitude"
                name="longitude"
                data-cy="longitude"
                type="text"
              />
              <ValidatedField label={translate('myApp.locations.name')} id="locations-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                id="locations-images"
                name="images"
                data-cy="images"
                label={translate('myApp.locations.images')}
                type="select"
              >
                <option value="" key="0" />
                {images
                  ? images.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/locations" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LocationsUpdate;
