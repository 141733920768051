import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserDataDownloads from './user-data-downloads';
import UserDataDownloadsDetail from './user-data-downloads-detail';
import UserDataDownloadsUpdate from './user-data-downloads-update';
import UserDataDownloadsDeleteDialog from './user-data-downloads-delete-dialog';

const UserDataDownloadsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserDataDownloads />} />
    <Route path="new" element={<UserDataDownloadsUpdate />} />
    <Route path=":id">
      <Route index element={<UserDataDownloadsDetail />} />
      <Route path="edit" element={<UserDataDownloadsUpdate />} />
      <Route path="delete" element={<UserDataDownloadsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserDataDownloadsRoutes;
