import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Button } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Home, Brand } from './header-components';
import { AdminMenu, AccountMenu, LocaleMenu } from '../menus';
import { useAppDispatch } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { Link } from 'react-router-dom';
import { NavDropdown } from '../menus/menu-components';
import MenuItem from '../menus/menu-item';
import LoginModal from 'app/modules/login/login-modal';
import Login from 'app/modules/login/login';
import { useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import SideBar from '../sidebar/SideBar';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  userName: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const loginError = useAppSelector(state => state.authentication.loginError);

  const handleLocaleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const handleLogin = (username, password, rememberMe = false) => {
    dispatch(login(username, password, rememberMe));
  };

  const [showModal, setShowModal] = useState(false);
  console.log(showModal, 'showModal');

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const renderDevRibbon = () =>
    !props.isInProduction ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="md" fixed="top" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <div>
          <Nav id="header-tabs" className="ms-auto" navbar>
            {props.isAuthenticated ? (
              <>
                {props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} showDatabase={!props.isInProduction} />}
                <NavDropdown icon="fa-solid fa-plus" id="account-menu" data-cy="accountMenu">
                  <h6 className="px-3 py-2 mb-0 border-bottom">CREATE:</h6>
                  <MenuItem to="/map-regions/new" data-cy="mapRegions">
                    Map Region
                  </MenuItem>
                  <MenuItem to="/albums/new" data-cy="albums">
                    Albums
                  </MenuItem>
                </NavDropdown>
                <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                <AccountMenu isAuthenticated={props.isAuthenticated} name={props.userName} />
              </>
            ) : (
              // Show Sign In  button when logged out
              <>
                <Link to="/login">
                  <Button className="theme-btn py-2 px-3 text-nowrap" onClick={toggleModal}>
                    Sign In
                  </Button>
                </Link>
                {/* <Link to="/account/register">
                  <Button color="primary">
                    Create Account
                  </Button>
                </Link> */}
              </>
            )}
          </Nav>
        </div>
        <div style={{ display: 'none' }}>
          <Collapse isOpen={menuOpen} navbar>
            <SideBar />
          </Collapse>
        </div>
      </Navbar>
      <LoginModal showModal={showModal} handleLogin={handleLogin} handleClose={toggleModal} loginError={loginError} />
    </div>
  );
};

export default Header;
