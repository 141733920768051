import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Albums from './albums';
import AlbumsDetail from './albums-detail';
import AlbumsUpdate from './albums-update';
import AlbumsDeleteDialog from './albums-delete-dialog';
import Products from './products';

const AlbumsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Albums />} />
    <Route path="products" element={<Products />} />
    <Route path="new" element={<AlbumsUpdate />} />
    <Route path=":id">
      <Route index element={<AlbumsDetail />} />
      <Route path="edit" element={<AlbumsUpdate />} />
      <Route path="delete" element={<AlbumsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AlbumsRoutes;
