import { IPosts } from 'app/shared/model/posts.model';

export interface ICommunityImages {
  id?: number;
  communityImageId?: number | null;
  createdDate?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  s3Key?: number | null;
  url?: number | null;
  croppedUrl?: number | null;
  waterMarkedUrl?: number | null;
  fileName?: string | null;
  posts?: IPosts | null;
}

export const defaultValue: Readonly<ICommunityImages> = {};
