import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Experiences from './experiences';
import ExperiencesDetail from './experiences-detail';
import ExperiencesUpdate from './experiences-update';
import ExperiencesDeleteDialog from './experiences-delete-dialog';

const ExperiencesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Experiences />} />
    <Route path="new" element={<ExperiencesUpdate />} />
    <Route path=":id">
      <Route index element={<ExperiencesDetail />} />
      <Route path="edit" element={<ExperiencesUpdate />} />
      <Route path="delete" element={<ExperiencesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ExperiencesRoutes;
