import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICommunityImages } from 'app/shared/model/community-images.model';
import { getEntities } from './community-images.reducer';

export const CommunityImages = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const communityImagesList = useAppSelector(state => state.communityImages.entities);
  const loading = useAppSelector(state => state.communityImages.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  return (
    <div>
      <h2 id="community-images-heading" data-cy="CommunityImagesHeading">
        <Translate contentKey="myApp.communityImages.home.title">Community Images</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="myApp.communityImages.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/community-images/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="myApp.communityImages.home.createLabel">Create new Community Images</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive mb-3">
        {communityImagesList && communityImagesList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="myApp.communityImages.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.communityImageId">Community Image Id</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.createdDate">Created Date</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.latitude">Latitude</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.longitude">Longitude</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.s3Key">S 3 Key</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.url">Url</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.croppedUrl">Cropped Url</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.waterMarkedUrl">Water Marked Url</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.fileName">File Name</Translate>
                </th>
                <th>
                  <Translate contentKey="myApp.communityImages.posts">Posts</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {communityImagesList.map((communityImages, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/community-images/${communityImages.id}`} color="link" size="sm">
                      {communityImages.id}
                    </Button>
                  </td>
                  <td>{communityImages.communityImageId}</td>
                  <td>{communityImages.createdDate}</td>
                  <td>{communityImages.latitude}</td>
                  <td>{communityImages.longitude}</td>
                  <td>{communityImages.s3Key}</td>
                  <td>{communityImages.url}</td>
                  <td>{communityImages.croppedUrl}</td>
                  <td>{communityImages.waterMarkedUrl}</td>
                  <td>{communityImages.fileName}</td>
                  <td>{communityImages.posts ? <Link to={`/posts/${communityImages.posts.id}`}>{communityImages.posts.id}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/community-images/${communityImages.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/community-images/${communityImages.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/community-images/${communityImages.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="myApp.communityImages.home.notFound">No Community Images found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CommunityImages;
