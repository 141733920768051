import { IUsers } from 'app/shared/model/users.model';

export interface IUserLikePost {
  id?: number;
  postId?: number | null;
  userId?: number | null;
  users?: IUsers[] | null;
}

export const defaultValue: Readonly<IUserLikePost> = {};
