import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/images">
        <Translate contentKey="global.menu.entities.images" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/users">
        <Translate contentKey="global.menu.entities.users" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/albums">
        <Translate contentKey="global.menu.entities.albums" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/experiences">
        <Translate contentKey="global.menu.entities.experiences" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/deleted-user-otp">
        <Translate contentKey="global.menu.entities.deletedUserOtp" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/locations">
        <Translate contentKey="global.menu.entities.locations" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/comments">
        <Translate contentKey="global.menu.entities.comments" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/purchase-info">
        <Translate contentKey="global.menu.entities.purchaseInfo" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-data-shares">
        <Translate contentKey="global.menu.entities.userDataShares" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/front-image">
        <Translate contentKey="global.menu.entities.frontImage" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-data-downloads">
        <Translate contentKey="global.menu.entities.userDataDownloads" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/community-images">
        <Translate contentKey="global.menu.entities.communityImages" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/flagged-posts">
        <Translate contentKey="global.menu.entities.flaggedPosts" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/regions">
        <Translate contentKey="global.menu.entities.regions" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/flagged-reasons">
        <Translate contentKey="global.menu.entities.flaggedReasons" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/blocked-users">
        <Translate contentKey="global.menu.entities.blockedUsers" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product-identifiers">
        <Translate contentKey="global.menu.entities.productIdentifiers" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/posts">
        <Translate contentKey="global.menu.entities.posts" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-devices">
        <Translate contentKey="global.menu.entities.userDevices" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-like-post">
        <Translate contentKey="global.menu.entities.userLikePost" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/notifications">
        <Translate contentKey="global.menu.entities.notifications" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
