import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, FormGroup, Input, Label, Nav, Row, Table } from 'reactstrap';
import { byteSize, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from '../../../scrollTop';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRegions } from 'app/shared/model/regions.model';
import { getEntities, getSearchEntities } from './regions.reducer';
import MapRegionSwitch from './map-region-switch';
import Search from 'app/override/components/common/search';

export const Regions = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [customLoading, setCustomLoading] = useState(false);

  const regionsList = useAppSelector(state => state.regions.entities);
  const loading = useAppSelector(state => state.regions.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const searchHandle = (value: string) => {
    if (value) {
      dispatch(getSearchEntities(value));
    } else {
      dispatch(getEntities({}));
    }
  };

  return (
    <>
      <ScrollToTop />
      {loading || customLoading ? (
        <div className="loader">
          <span></span>
        </div>
      ) : (
        ''
      )}
      <div>
        <h3 id="regions-heading" data-cy="RegionsHeading" className="page-title">
          <Translate contentKey="myApp.regions.home.title">Map Regions Overview</Translate>
        </h3>
        <div>
          <Search searchHandle={searchHandle} />
        </div>
        <div>
          {regionsList && regionsList.length > 0 ? (
            <Row className="g-4">
              {regionsList.map((item, i) => (
                <Col className="mb-2" xs="12" md="6" lg="4" xl="3" key={i}>
                  <Card className="h-100 p-0 shadow-sm rounded-0 border-0">
                    <Nav navbar className="h-100">
                      <img className="card-img-style" alt="Sample" src={item?.defaultImageUrl} />
                      <CardBody className="p-0 d-flex flex-wrap align-items-stretch">
                        <div className="d-flex justify-content-between align-items-center px-2 py-2 mb-2 border-bottom align-self-start w-100">
                          <MapRegionSwitch data={item} type="switch" setLoading={setCustomLoading} />
                          <FormGroup check className="px-1">
                            <Label check>
                              {/* <Input type="checkbox" defaultChecked={item?.paid} /> */}
                              <MapRegionSwitch data={item} type="checkbox" setLoading={setCustomLoading} />
                              {/* {checked ? <div> Paid </div> : <div> Free </div>} */}
                            </Label>
                          </FormGroup>
                        </div>
                        <CardTitle className="px-2 align-self-start w-100" tag="h5">
                          {item?.name}
                        </CardTitle>
                        <CardSubtitle className="mb-0 text-muted px-2 py-2 align-self-end w-100 gap-2" tag="h6">
                          <div className="">{item?.imagesCount} photos</div>
                          <div className="card-icons d-flex gap-2">
                            {/* <FontAwesomeIcon icon="pencil-alt" />
                          <FontAwesomeIcon icon="trash" /> */}
                            <Button onClick={() => navigate(`${item.regionId}`)}>
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                            {/* <Button onClick={() => deleteHandle(images.imageId)}> */}
                            <Button onClick={() => navigate(`${item.regionId}/delete`)}>
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          </div>
                        </CardSubtitle>
                      </CardBody>
                    </Nav>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="myApp.regions.home.notFound">No Regions found</Translate>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Regions;
