import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserDataShares from './user-data-shares';
import UserDataSharesDetail from './user-data-shares-detail';
import UserDataSharesUpdate from './user-data-shares-update';
import UserDataSharesDeleteDialog from './user-data-shares-delete-dialog';

const UserDataSharesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserDataShares />} />
    <Route path="new" element={<UserDataSharesUpdate />} />
    <Route path=":id">
      <Route index element={<UserDataSharesDetail />} />
      <Route path="edit" element={<UserDataSharesUpdate />} />
      <Route path="delete" element={<UserDataSharesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserDataSharesRoutes;
