import { IExperiences } from 'app/shared/model/experiences.model';
import { IUserLikePost } from 'app/shared/model/user-like-post.model';
import { IBlockedUsers } from 'app/shared/model/blocked-users.model';
import { IPurchaseInfo } from 'app/shared/model/purchase-info.model';

export interface IUsers {
  id?: number;
  email?: string | null;
  socialAccessToken?: string | null;
  privacyPolicyAccepted?: boolean | null;
  providerId?: string | null;
  validateEmailToken?: string | null;
  createdDate?: number | null;
  experiencescount?: number | null;
  isActivated?: boolean | null;
  termsAccepted?: boolean | null;
  isAdmin?: boolean | null;
  likescount?: number | null;
  modifiedDate?: number | null;
  userAccountStatus?: boolean | null;
  accountDeletionReason?: string | null;
  userAccessToken?: string | null;
  name?: string | null;
  provider?: string | null;
  password?: string | null;
  postscount?: number | null;
  profileImageUrl?: string | null;
  experiences?: IExperiences | null;
  userLikePost?: IUserLikePost | null;
  blockedUsersTo?: IBlockedUsers | null;
  blockedUsersFrom?: IBlockedUsers | null;
  purchaseInfoTo?: IPurchaseInfo | null;
  purchaseInfoFrom?: IPurchaseInfo | null;
}

export const defaultValue: Readonly<IUsers> = {
  privacyPolicyAccepted: false,
  isActivated: false,
  termsAccepted: false,
  isAdmin: false,
  userAccountStatus: false,
};
