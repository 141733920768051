import { IUsers } from 'app/shared/model/users.model';
import { IProductIdentifiers } from 'app/shared/model/product-identifiers.model';

export interface IPurchaseInfo {
  id?: number;
  purchaseInfoId?: number | null;
  createdDate?: number | null;
  productIdentifierId?: number | null;
  productUserTos?: IUsers[] | null;
  productUserFroms?: IUsers[] | null;
  productIdentifiers?: IProductIdentifiers | null;
}

export const defaultValue: Readonly<IPurchaseInfo> = {};
